import Vue from 'vue'
import Vuex from 'vuex'
import modules from "./modules";

Vue.use(Vuex);

const mutations = {
  SET_LOADING: (state, payload) => {
    state.loading = !!payload;
  },

  SET_ERROR: (state, { message }) => {
    state.error = message;
  },

  SET_SPLASHSCREEN: (state, payload) => {
    state.splashscreen = !!payload;
  }
};

const state = {
  loading: false,
  error: "",
  splashscreen: false
};


export default new Vuex.Store({
  state: {
    ...state,
    packageVersion: process.env.PACKAGE_VERSION || '0'
  },
  getters: {
    appVersion: (state) => {
      return state.packageVersion
    }
  },
  mutations,
  actions: {
  },
  modules,
})
