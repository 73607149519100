<template>
  <v-layout>
    <product-grid-card
      v-if="theme?.product_display == 'grid'"
    />
    <product-list-card
      v-if="theme?.product_display == 'list'"
    />
  </v-layout>
</template>

<script>
import ProductGridCard from "./ProductGridCard";
import ProductListCard from "./ProductListCard";

export default {
    name: "product-card-skeletons",
    props: ["theme"],
    components: {
        ProductGridCard,
        ProductListCard
    }
}
</script>