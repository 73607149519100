import addresses from './addresses';
import auth from './auth';
import cards from './cards';
import products from './products';
import categories from './categories';
import coupons from './coupons';
import bag from './bag';
import indications from './indications';
import customers from './customers';
import tables from './tables';
import districts from './districts';
import orders from './orders';
import theme from './theme';

export default {
    addresses,
    auth,
    cards,
    products,
    categories,
    coupons,
    bag,
    indications,
    customers,
    tables,
    districts,
    orders,
    theme
}