<template>
  <div class="d-flex align-center justify-space-between">
    <div class="d-flex flex-column mb-5">
      <span style="font-size: 16px" class="gray--text mb-2"
        >Endereço de entrega</span
      >
      <div>
        <span>{{ address?.street }}</span
        >,
        <span>{{ address?.number }}</span>
        <!-- TODO: Adicionar referência -->
        <!-- <span v-if="">{{ address?.number }}</span> -->
      </div>
      <div>
        <span v-if="address?.district">{{ address?.district }}</span>
        <span v-if="address?.city"> - {{ address?.city }}</span>
        <span>{{ address?.state }}</span>
      </div>
      <div class="mt-1">
        <span class="ml-3 caption gray--text"
          >- Identificador: {{ address?.name }}</span
        ><br />
        <span
          v-if="theme?.district_tax && address?.tax > 0"
          class="ml-3 caption gray--text"
        >
          - Taxa de entrega {{ formatMoney(address?.tax) }}
        </span>
      </div>
    </div>
    <div class="mr-3" v-if="otherAddress ?? false">
      <v-btn outlined x-small color="primary" @click="$emit('showSheet', true)">
        alterar
        <v-icon style="font-size: 16px">mdi-swap-vertical</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "currentAddressCard",
  props: ["address", "otherAddress", "theme"],
  data() {
    return {};
  },
};
</script>

<style scoped>
.current-address {
  color: #fff;
}
.current-address .v-card__subtitle {
  color: #fff;
}
.current-address button {
  color: #fff;
}
</style>