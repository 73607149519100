<template>
    <v-text-field
        :value="copy"
        class="centered-input"
        name="pix-key"
        autofocus
        label="Chave pix (Copia e cola)"
        :append-icon="'mdi-content-copy'"
        @click="copyToClipboard"
        @click:append="copyToClipboard"
        persistent-hint
        :hint="pix_name ? `Recebedor: ${pix_name}` : null"
        readonly
        counter
    ></v-text-field>
</template>

<script>
export default {
    name: "CopyToClipboard",
    props: ['copy', 'pix_name'],
    methods: {
        async copyToClipboard() {
            try {
                await navigator.clipboard.writeText(this.copy);
                this.$toast.info('Copiado para área de transferência');
            } catch($e) {
                this.$toast.error('Não foi possível copiar');
            }
        }
    }
}
</script>

<style scoped>
.centered-input :deep input {
    text-align: center
}

.centered-input :deep input {
    cursor: pointer;
}
</style>