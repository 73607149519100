<template>
    <v-row>
        <v-col cols="12" sm="12" md="6" xl="4" v-for="i in 3" :key="i">
            <v-card 
                outlined
                style="border-radius: 15px;"
                class="d-flex">
                <v-skeleton-loader
                    type="image"
                    width="130"
                    height="130"
                    style="border-radius: 15px;"
                ></v-skeleton-loader>
                <div class="d-flex flex-column">
                    <v-skeleton-loader
                        class="ml-3 mr-3 mt-2"
                        type="text"
                        width="200"
                    ></v-skeleton-loader>
                    <div class="d-flex align-end justify-end" style="height: 100%;">
                        <v-skeleton-loader
                            class="mr-5 mb-2"
                            style="border-radius: 20px;"
                            type="button"
                        ></v-skeleton-loader>
                    </div>
                </div>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: "product-list-card-skeletons"
}
</script>