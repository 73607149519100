import Vue from 'vue';
import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

Vue.use(ToastPlugin, {
    // One of the options
    position: 'top',
    duration: 3000
});

export default Vue;