<template>
  <div>
    <div
      style="background: #fff;"
      class="py-0 pt-0 d-flex justify-center text-center"
    >
    <v-container style="padding: 0px !important;">
      <div 
        class="banner-cover"
        :style="`${theme?.cover ? 'background: url(' + theme?.cover + ');' : ''}`"
      >
          <div>
            <div class="d-flex logo-position" :style="`${theme?.logoBackground ? '' : 'width: 0px;'}`">
              <div :class="`logo-box ${theme?.logoBackground ? 'logo-background' : ''}`">
                <v-img
                  :src="theme.logo"
                  lazy-src="https://picsum.photos/id/11/100/6"
                  :class="`logo ${theme?.logoBackground ? '' : 'ml-2'}`"
                >
                </v-img>
              </div>
              <company-name :theme="theme" :show="true"></company-name>
            </div>
          </div>
        </div>
      </v-container>
    </div>
    <v-container>
      <div :class="`company-description ${theme?.logoBackground ? '' : 'company-description-without-background'}`">
        {{ theme?.siteDescription }}
      </div>
      <div
        class="d-flex align-center align-start mt-8"
        v-if="theme.free_rate_from && theme.free_rate_from > 0"
      >
        <v-icon class="mr-2 mb-2">mdi-motorbike</v-icon>
        <div class="d-flex flex-column align-start">
          <span class="font-weight-bold">
            Frete grátis
            <v-icon style="font-size: 10px; color: #fff">mdi-money</v-icon>
          </span>
          <span class="font-weight-bold primary--text mb-3"
            >A partir de {{ theme.free_rate_from / 100 }} R$</span
          >
        </div>
      </div>
      <schedule
        v-model="openSchedule"
        :closed="theme?.closed"
        :schedule="theme?.schedule"
      >
      </schedule>
      <v-divider class="mb-3 mt-2"></v-divider>
      <div class="d-flex justify-center">
        <cashback-rules-dialog
          class="mb-5"
          v-if="theme?.active_cashback && theme?.cashback_settings?.cashback_by_total_amount && theme?.cashback_settings?.cashback_rules"
          :cashbackRules="theme?.cashback_settings?.cashback_rules"
        />
      </div>
      <v-text-field
        label="Pesquisar"
        class="mt-2"
        append-icon="mdi-magnify"
        v-model="search"
        @input="searchProducts"
        dense
        single-line
        rounded
        outlined
        hint="Digite o nome do produto que procura"
      />
      <v-layout
        v-if="categoriesLoading"
        class="text-center d-flex flex-column align-start"
      >
        <span class="font-weight-bold text-uppercase"
          >Encontre o que procura</span
        >
        <chip-skeleton></chip-skeleton>
      </v-layout>
      <div v-else>
        <div class="mb-4">
          <span class="font-weight-bold text-uppercase"
            >Encontre o que procura</span
          >
          <v-chip-group mandatory>
            <v-chip
              class="ma-2 ml-0"
              v-for="(category, i) in categories"
              :key="i"
              :color="category.id === selectedCategory?.id ? 'primary' : ''"
              @click="setCategory(category)"
            >
              {{ category.name }}
              <v-avatar v-if="category.id === selectedCategory?.id">
                <v-icon>mdi-filter</v-icon>
              </v-avatar>
            </v-chip>
          </v-chip-group>
        </div>
        <div v-if="selectedCategory" class="my-2">
          <span class="font-weight-bold">Filtrando por:</span>
          {{ selectedCategory.name }}
        </div>
      </div>
      <div v-if="this.search === '' && selectedCategory == ''">
        <div v-if="highlights.length > 0 || highlightsLoading">
          <span class="font-weight-bold text-uppercase">Mais pedidos</span>
          <v-layout>
            <highlights-skeleton
              v-if="highlightsLoading"
              :products="highlights"
            ></highlights-skeleton>
            <highlights
              v-else-if="highlights"
              :products="highlights"
            ></highlights>
          </v-layout>
        </div>
      </div>
      <div
        v-infinite-scroll="loadMoreProduct"
        infinite-scroll-disabled="busy"
        :infinite-scroll-distance="products.data.length * 100"
      >
        <div
          class="mt-3"
          v-for="(products, category_name) in groupedProduct"
          :key="category_name"
        >
          <div
            :class="`category-title text-uppercase
              ${
                (Object.values(groupedProduct)[0][0]?.category_name ?? '') ==
                category_name
                  ? ''
                  : 'mt-8'
              }
            my-3`"
          >
            {{ category_name }}
          </div>
          <product-list :products="products" :theme="theme" />
        </div>
      </div>
      <v-layout v-if="loading" class="text-center mt-5">
        <product-card-skeleton :theme="theme" />
      </v-layout>
      <div v-if="!loading && products.data.length == 0" class="text-center">
        <v-icon color="primary" size="50">mdi-alert-circle-outline</v-icon>
        <span class="font-weight-bold">Nenhum resultado encontrado</span>
      </div>
    </v-container>
  </div>
</template>

<script>
import TextField from "../components/inputs/TextField";
import ChipSkeleton from "../components/skeletons/Chips";
import Schedule from "../components/Schedule";
import CompanyName from "../components/CompanyName";
import ProductCardSkeleton from "../components/skeletons/ProductCard";
import HighlightsSkeleton from "../components/skeletons/Highlights";
import CashbackRulesDialog from "../components/modals/CashbackRulesDialog";
import ProductList from "../components/ProductList";
import Highlights from "../components/slides/Highlights";
import _debounce from "lodash/debounce";
import { mapState, mapActions } from "vuex";

export default {
  name: "Home",

  components: {
    TextField,
    CompanyName,
    ProductList,
    ChipSkeleton,
    ProductCardSkeleton,
    Schedule,
    CashbackRulesDialog,
    HighlightsSkeleton,
    Highlights,
  },

  async created() {
    if (this.categories.length == 0) {
      this.getCategories({});
    }

    if (this.highlights.length == 0) {
      this.getHighlights({});
    }

    if (this.products.data.length == 0) {
      this.getProducts({});
    }

    this.requestPermission();
  },

  data() {
    return {
      search: "",
      busy: false,
      scheduleDialog: false,
      selectedCategory: "",
    };
  },

  computed: {
    ...mapState({
      openSchedule: (state) => state.theme.openSchedule,
      theme: (state) => state.theme.theme,
      categories: (state) => state.categories.categories,
      categoriesLoading: (state) => state.categories.loading,
      products: (state) => state.products.products,
      highlights: (state) => state.products.highlights,
      loading: (state) => state.products.loading,
      highlightsLoading: (state) => state.products.highlightsLoading,
    }),
    groupedProduct() {
      return this.products.data.reduce((group, product) => {
        let { category_name } = product;
        group[category_name] = group[category_name] ?? [];
        group[category_name].push(product);
        return group;
      }, {});
    },
  },

  methods: {
    ...mapActions(["getProducts", "getCategories", "getHighlights"]),

    searchProducts: _debounce(async function () {
      await this.getProducts({
        search: this.search,
        categoryId: this.selectedCategory.id,
      });
    }, 600),
    // showNotificationPermissionRequest() {
    //   if (Notification.permission === 'default') {
    //     var options = {
    //       body: 'Seja bem-vindo ao <nome do site>! Para aprimorar sua experiência e receber notificações de promoções e descontos, clique no botão "Eu quero".',
    //       icon: 'caminho/para/icone.png',
    //       requireInteraction: true,
    //       actions: [
    //         { action: 'confirmar', title: 'Eu quero' }
    //       ]
    //     };

    //     var notification = new Notification('Permissão de Notificação', options);

    //     notification.addEventListener('notificationclick', function(event) {
    //       if (event.action === 'confirmar') {

    //       }

    //       // notification.close();
    //     });
    //   }
    // },
    requestPermission() {
      if ("Notification" in window && navigator.serviceWorker) {
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
            // this.showNotificationPermissionRequest();
          } else if (permission === "denied") {
          } else {
          }
        });
      }
    },

    async loadMoreProduct() {
      let cantLoadMore =
        this?.products.data.length >= this?.products?.meta?.total;

      if (this.busy || this.loading || cantLoadMore) return;

      this.busy = true;

      await this.getProducts({
        search: this.search,
        categoryId: this.selectedCategory.id,
        page: this?.products?.meta?.current_page + 1,
      });

      this.busy = false;
    },

    async setCategory(category) {
      this.selectedCategory =
        this.selectedCategory?.id === category.id ? "" : category;

      await this.getProducts({
        search: this.search,
        categoryId: this.selectedCategory.id,
      });
    },
  },
};
</script>

<style >
.banner-cover {
  width: 100%;
  min-height: 150px;
  background-size: cover !important;
  background-color: var(--v-secondary);
}

.logo-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  border-radius: 25px;
}

.logo {
  margin: 0 auto;
  /* transform: translate(0%, 80px); */
  background: var(--v-primary);
  max-width: 70px;
}

.logo-position {
  transform: translate(15px, 85px);
  width: 90%;
}

.company-description {
  margin-top: -5px;
  margin-left: 110px;
  font-weight: 500;
}

.company-description-without-background {
  margin-left: 88px;
}

@media (min-width: 768px) {
  .banner-cover {
    border-radius: 0px 0px 20px 20px;
  }

  .page-top-margin {
    margin-top: 60px !important;
  }

  .logo-position {
    transform: translate(25px, 80px);
  }

  .company-description {
    margin-left: 120px;
  }

  .company-description-without-background {
    margin-top: -5px;
    margin-left: 97px;
  }
}

.banner {
  padding-top: 20px;
  background: #fff;
  height: 100px;
  border-radius: 300% 300% 0px 0px;
  position: absolute;
  bottom: 0px;
  width: 100%;
}

.category-title {
  font-weight: 700;
  font-size: 16px;
}
</style>
