import axios from "axios";
import config from "@/config/api";

import { user, token } from '@/utils/auth';

window.axios = axios.create({
  baseURL: config.url
});

window.axios.defaults.headers.common["Accept"] = "application/json";
window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
window.axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded";

if(user())
  window.axios.defaults.headers.common["Authorization"] = `Bearer ${token()}`;

export default window.axios;