import { index } from "@/api/categories";

export default {
    state: {
        categories: [],
        loading: false,
        errors: {
            message: "",
            errors: ""
        }
    },
    mutations: {
        setCategories(state, { categories }) {
            state.categories = categories;
        },

        setCategoriesLoading(state, { loading }) {
            state.loading = loading;
        },

        setCategoriesErrors (state, { errors }) {
            state.errors = errors;
        }
    },
    actions: {
        // commit, rootState
        async getCategories({ commit }, { search = '' }) {
            try {
                commit('setCategoriesLoading', { loading: true });

                let { data } = await index({ search });

                commit('setCategories', { categories: data });
            } catch (error) {
                let response = error.response.data;
                commit("setCategoriesError", { errors: response });
            } finally {
                commit('setCategoriesLoading', { loading: false });
            }
        },
    }
}