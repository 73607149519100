export const authenticated = () => {
    return !!localStorage.getItem("oauth:access_token");
}

export const user = () => {
    return JSON.parse(localStorage.getItem("user"))?.customer;
}

export const token = () => {
    return JSON.parse(localStorage.getItem("user"))?.token;
}