var render = function render(){var _vm=this,_c=_vm._self._c;return _c('swiper',{staticClass:"mySwiper",attrs:{"slides-per-view":1,"space-between":100,"centeredSlides":true,"slideToClickedSlide":true,"breakpoints":{
    '@0.00': {
      slidesPerView: 2,
      spaceBetween: 10,
    },
    '@0.75': {
      slidesPerView: 2,
      spaceBetween: 20,
    },
    '@1.00': {
      slidesPerView: 3,
      spaceBetween: 40,
    },
  }}},_vm._l((5),function(i){return _c('swiper-slide',{key:i},[_c('v-skeleton-loader',{staticStyle:{"border-radius":"23px"},attrs:{"type":"image","width":"300","height":"200"}})],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }