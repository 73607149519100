<template>
    <div class="mt-5">
        <page-title title="Novo cartão"></page-title>
        <v-container>
            <v-layout align-center justify-center="" class="mt-10">
                <v-flex>
                    <card-form 
                        action="create"
                        :disableEmail="!!user?.email"
                        v-model="card"
                        :theme="theme"
                        :loading="loading"
                        @submit="submit" />
                </v-flex>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
import PageTitle from "@/components/layouts/PageTitle";
import CardForm from "@/components/forms/Card";
import { loadMercadoPago } from "@mercadopago/sdk-js";
import { mapActions, mapState } from 'vuex';

export default {
    name: "new-card",
    props: ['withoutRedirect'],
    components: {
        PageTitle,
        CardForm
    },
    data() {
        return {
            card: {
                email: "",
                cardName: "",
                cardNumber: "",
                cardMonth: "",
                cardYear: "",
                cardCvv: "",
            },
            mercadopagoAPI: null
        }
    },
    async mounted() {
        await loadMercadoPago();
        this.mercadopagoAPI = new window.MercadoPago(this.theme.mercadopago_public_key, {
            locale: "pt-BR",
        });

        this.card.email = this.user.email;
    },
    computed: {
        ...mapState({
            user: state => state.auth.user,
            theme: (state) => state.theme.theme,
            loading: state => state.cards.loading,
            errors: state => state.cards.errors
        })
    },
    methods: {
        async submit() {
            let cardNumber = this.card.cardNumber.replace(/ /g, "");
            let brand = '';
            let thumbnail = '';
            let cardToken = '';

            if (cardNumber) {
                cardToken = await this.mercadopagoAPI.createCardToken({
                    cardNumber: cardNumber.replace(/ /g, ""),
                    cardholderName: this.card.cardName,
                    cardExpirationMonth: this.card.cardMonth,
                    cardExpirationYear: this.card.cardYear,
                    securityCode: this.card.cardCvv,
                });
    
                let paymentMethodResult =  await this.mercadopagoAPI.getPaymentMethods({
                    bin: cardNumber.substring(0, 8),
                });
    
                brand = paymentMethodResult.results[0]?.id ?? '';
                thumbnail = paymentMethodResult.results[0]?.thumbnail ?? '';
            }

            let formInput = {
                email: this.card.email,
                first_6: cardNumber.substring(0, 6),
                last_4: cardNumber.substring(cardNumber.length - 4),
                brand: brand,
                thumbnail: thumbnail,
                holder: this.card.cardName,
                expiration_date: `${this.card.cardMonth}/${this.card.cardYear}`,
                cardToken: cardToken.id
            };

            try {
                let newCard = await this.storeCard({ form: formInput });
                this.$toast.success("Cartão cadastrado com sucesso!");

                if (this.withoutRedirect) {
                    this.resetCard();
                    this.$emit('cardSaved', newCard);
                } else {
                    this.$router.replace({ name: 'cards-index' });
                }

                await this.getCards({ search: null });
            } catch (error) {
                this.$toast.error("Houve um erro ao tentar cadastrar novo cartão!");
            }
        },
        resetCard() {
            this.card = {
                email: "",
                cardName: "",
                cardNumber: "",
                cardMonth: "",
                cardYear: "",
                cardCvv: "",
            };
        },
        ...mapActions(["storeCard", "getCards"])
    }
}
</script>