import { getCoupon } from "@/api/coupons";

export default {
    state: {
        coupons: [],
        coupon: null,
        loading: false,
        errors: {
            message: "",
            errors: ""
        }
    },
    mutations: {
        setCoupons(state, { coupons }) {
            state.coupons = coupons;
        },

        setCoupon(state, { coupon }) {
            state.coupon = coupon;
        },

        setCouponsLoading(state, { loading }) {
            state.loading = loading;
        },

        setCouponsErrors (state, { errors }) {
            state.errors = errors;
        }
    },
    actions: {
        // commit, rootState
        async getCoupon({ commit }, { code = '' }) {
            try {
                commit('setCouponsLoading', { loading: true });

                commit('setCoupon', { coupon: null });

                if (!code) return false;

                let { data } = await getCoupon({ code });

                commit('setCoupon', { coupon: data });
            } catch (error) {
                let response = error.response.data;
                commit("setCouponsError", { errors: response });
            } finally {
                commit('setCouponsLoading', { loading: false });
            }
        },
    }
}