<template>
  <auth>
    <forget-password-sheet
      v-model="sheet"
      @close="sheet = false"
    ></forget-password-sheet>
    <v-layout style="max-width: 400px" column>
      <v-flex d-flex justify-center class="mb-5">
        <!-- <v-icon size="60" @click="goTo('/')">
                home
            </v-icon> -->
        <v-avatar
          color="white"
          size="130"
          style="box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5)"
        >
          <v-img :src="theme.logo" :class="`${theme?.logoBackground ? 'logo-background' : ''}`"/>
        </v-avatar>
      </v-flex>
      <v-flex d-flex justify-center>
        <h4 class="primary--text">Faça seu login</h4>
      </v-flex>
      <v-flex> </v-flex>
      <v-form @submit="signIn">
        <v-flex style="margin-top: 20px">
          <v-text-field
            v-if="theme?.form?.show_password_field"
            color="primary"
            prepend-icon="mdi-email"
            name="email"
            v-model="email"
            :error="!!errors?.email"
            :error-messages="errors?.email && errors?.email[0]"
            label="Email"
          />
          <v-text-field
            v-else
            color="primary"
            prepend-icon="mdi-phone"
            name="phone"
            v-mask="phoneMask"
            v-model="phone"
            placeholder="(xx) xxxxx-xxxx"
            :error="!!errors?.phone"
            :error-messages="errors?.phone && errors?.phone[0]"
            label="Telefone"
          />

          <v-text-field
            color="primary"
            v-if="theme?.form?.show_password_field"
            prepend-icon="mdi-lock"
            v-model="password"
            label="Senha"
            :error="!!errors?.password"
            :error-messages="errors?.password && errors?.password[0]"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            name="input-10-1"
            @click:append="showPassword = !showPassword"
          />
        </v-flex>
        <v-flex class="my-2">
          <v-btn
            color="primary"
            dark
            rounded=""
            type="submit"
            :loading="loading"
            block
          >
            Entrar
          </v-btn>
        </v-flex>
      </v-form>

      <!-- <v-flex >
            <a link="#" 
                style="margin-top: 20px; font-size:1.1rem" 
                class="d-flex flex-row-reverse"
                @click="goTo('/cadastrar')">
                Novo por aqui? Inscrevar-se! 
            </a>
            <v-btn text color="primary" href="#" class="pa-0" @click="goTo('/cadastrar')">Esqueceu a senha?</v-btn>
        </v-flex> -->
      <v-flex class="d-flex flex-row-reverse">
        <div>
          <v-btn
            v-if="theme?.form?.show_password_field"
            text
            color="primary"
            href="#"
            class="mt-2 pa-0"
            @click="sheet = !sheet"
            >Esqueceu a senha?</v-btn
          >
        </div>
      </v-flex>
      <v-flex class="d-flex flex-row-reverse">
        <div>
          <v-btn
            text
            color="primary"
            href="#"
            class="pa-0"
            @click="goTo('/cadastrar')"
            >Novo por aqui? Inscrevar-se!</v-btn
          >
        </div>
      </v-flex>
    </v-layout>
  </auth>
</template>

<script>
import { mask } from 'vue-the-mask';
import Auth from "@/components/layouts/Auth";
import ForgetPasswordSheet from "@/components/sheets/ForgetPasswordSheet";
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      phoneMask: "(##) #####-####",
      email: "",
      phone: "",
      password: "",
      sheet: false,
      showPassword: false,
    };
  },
  directives: {mask},
  components: {
    Auth,
    ForgetPasswordSheet,
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      theme: (state) => state.theme.theme,
      loading: (state) => state.auth.loading,
      errors: (state) => state.auth.errors,
    }),
  },
  methods: {
    goTo(path) {
      this.$router.push(path);
    },
    signIn: async function (e) {
      e.preventDefault();

      await this.login({
        email: this.email,
        phone: this.phone,
        password: this.password,
      });

      if (this.user) {
            this.$toast.success(`Bem vindo, ${this.user.name}`);
      } else {
        if (this.email) {
            this.$toast.error("Email ou senha incorretos");
        } else {
            this.$toast.error("Telefone ainda não cadastrado");
        }
      }
    },
    ...mapActions(["login"]),
  },
};
</script>