<template>
  <div class="d-md-flex justify-center">
    <v-layout style="max-width: 1300px" column class="mt-5">
      <page-title title="Indicações"></page-title>

      
      <v-container>
        <div class="mt-8 text-center">
          <h3>Programa de Indicações <br/> Compartilhe e Ganhe!</h3>
          <p class="mt-5">Quando alguém comprar através do seu link de indicação, e tiver o pedido <span class="font-weight-bold">finalizado</span> (Apenas um pedido por cliente), você poderá receber premiações em créditos no aplicativo ao atigir as seguintes metas:</p>
        </div>
        <ul class="mt-5">
          <li v-for="goal in indicationsGoals" :key="goal.goal">
            <span>
              <span class="font-weight-bold">{{ goal.goal }} indicações</span> 
              você receberá <span class="font-weight-bold">{{ formatMoney(goal.reward) }}</span>
            </span>
          </li>
        </ul>
        <v-card class="pt-3 px-2 mt-7" style="height: 130px;">
          <div class="d-flex flex-column justify-center align-center text-center">
            <div class="mb-8 text-uppercase font-weight-bold">Progresso atual</div>
            <indications-progress
              :indeterminate="loading"
              :goals="indicationsGoals"
              :totalIndications="totalIndications"
            ></indications-progress>
          </div>
        </v-card>
        <div class="d-flex flex-column justify-center  mt-8 text-center">
          <p>Não fique de fora, compartilhe seu link com amigos e familiares</p>
          <v-btn color="primary" @click="shareOrCopy">
            <v-icon>mdi-share-variant</v-icon>
            Compartilhar
          </v-btn>
        </div>
        <indications-dialog
          @fetch="fetchIndications"
          :loading="loading"
          :indications="indications"
        ></indications-dialog>
      </v-container>
    </v-layout>
  </div>
</template>

<script>
import PageTitle from "@/components/layouts/PageTitle";
import IndicationsProgress from "@/components/IndicationsProgress";
import IndicationsDialog from "@/components/modals/IndicationsDialog";
import { mapState, mapActions } from "vuex";

export default {
  name: "indications",
  components: {
    PageTitle,
    IndicationsDialog,
    IndicationsProgress
  },
  computed: {
    ...mapState({
      theme: (state) => state.theme.theme,
      user: (state) => state.auth.user,
      indications: (state) => state.indications.indications,
      totalIndications: (state) => state.indications.totalIndications,
      indicatorCode: (state) => state.indications.indicatorCode,
      loading: (state) => state.indications.loading
    }),
    indicationsGoals() {
      let goals = Object.values(this.theme?.indications_settings?.indications_goals)
        .sort((goal, goalb) => goal.goal - goalb.goal);

      return goals;
    }
  },
  async mounted() {
    let isIndicationsActive = this.theme?.indications_settings?.is_active;

    if (!isIndicationsActive) {
      this.$router.push("/");
    }

    if (!this.user?.indicator_code) {
      await this.getIndicatorCode();
    }

    await this.getTotalIndications();
  },
  methods: {
    ...mapActions(["getIndications", "getIndicatorCode", "getTotalIndications"]),
    shareOrCopy() {
      if (navigator.share) {
        navigator.share({
          title: 'Link de indicação',
          text: 'Faça seu pedido pelo meu link de indicação',
          url: window.location.origin + '?indicator=' + (this.user?.indicator_code ?? this.indicatorCode)
        });
      }
    },
    async fetchIndications() {
      await this.getIndications();
    }
  }
};
</script>

<style>
</style>