import { index, create, find, update, destroy, setCurrentAddress } from "@/api/addresses";

export default {
    state: {
        addresses: [],
        loading: false,
        errors: {
            message: "",
            errors: ""
        }
    },
    mutations: {
        setAddresses(state, { addresses }) {
            state.addresses = addresses;
        },

        setAddressesLoading(state, { loading }) {
            state.loading = loading;
        },

        setAddressesErrors (state, { errors }) {
            state.errors = errors;
        }
    },
    actions: {
        // commit, rootState
        async getAddresses({ commit }, { search = '' }) {
            try {
                commit('setAddressesLoading', { loading: true });

                let { data } = await index({ search });

                commit('setAddresses', { addresses: data.data });
            } catch (error) {
                let response = error.response.data;
                commit("setAddressesErrors", { errors: response });
            } finally {
                commit('setAddressesLoading', { loading: false });
            }
        },
        async storeAddress({ commit }, { form }) {
            try {
                commit('setAddressesLoading', { loading: true });

                let { data } = await create({ form });

                return data;
            } catch (error) {
                let response = error.response.data;
                commit("setAddressesErrors", { errors: response });
                throw error;
            } finally {
                commit('setAddressesLoading', { loading: false });
            }
        },

        async setCurrentAddress({ commit }, { addressId }) {
            try {
                let { data } = await setCurrentAddress({ addressId });
            } catch(error) {
                console.log(error);
                let response = error.response.data;
                commit("setAuthErrors", { errors: response });
            }
        },

        async destroyAddress({ commit }, { addressId }) {
            try {
                let { data } = await destroy({ addressId });

                return data;
            } catch (error) {
                let response = error.response.data;
                commit("setAddressesErrors", { errors: response });
            }
        },

        async findAddress({ commit }, { addressId }) {
            try {
                commit('setAddressesLoading', { loading: true });

                let { data } = await find({ addressId });

                return data;
            } catch (error) {
                let response = error.response.data;
                commit("setAddressesErrors", { errors: response });
                throw error;
            } finally {
                commit('setAddressesLoading', { loading: false });
            }
        },

        async updateAddress({ commit }, { addressId, form }) {
            try {
                commit('setAddressesLoading', { loading: true });

                let { data } = await update({ addressId, form });

                return data;
            } catch (error) {
                let response = error.response.data;
                commit("setAddressesErrors", { errors: response });
                throw error;
            } finally {
                commit('setAddressesLoading', { loading: false });
            }
        },
    }
}