<template>
    <div>
        <div class="text-center">
    <v-dialog
      :value="value"
      @input="update"
      width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <slot></slot>
        <!-- <v-button
            :color="closed ? 'red' : 'green'"
            label
            ripple
            small
            outlined
            :text-color="closed ? 'red' : 'green'"
            v-bind="attrs"
            v-on="on"
        >
          <sl
        dkasdaskj asd
        </v-button> -->
      </template>

      <v-card>
        <v-card-title class="white--text primary">
            <v-icon style="font-size: 20px; color: #fff;" left>
                mdi-clock
            </v-icon>
            Horário de funcionamento
        </v-card-title>

        <v-card-text>
            <div class="d-flex flex-column mt-4">
                <span v-for="(daySetting, i) in scheduleSortedDays" :key="i">
                    <div class="d-flex justify-space-between" v-if="daySetting.day">
                        <div :class="daySetting.day == getDayName(new Date()) ? 'font-weight-bold' : ''">
                            {{ translateDayName(daySetting.day) }}
                        </div>
                        <div :class="`ml-3 ${daySetting.day == getDayName(new Date()) ? 'font-weight-bold' : ''}`">
                          <span v-if="closed && daySetting.day == getDayName(new Date()) || daySetting.closed">Fechado</span>
                          <span v-else-if="(daySetting.from && daySetting.to)">
                            {{ formatDateToTime(daySetting.from) }} - {{ formatDateToTime(daySetting.to) }}
                          </span>
                          <span v-else-if="daySetting.closed">Fechado</span>
                          <span v-else>24h</span>
                        </div>
                    </div>
                </span>
            </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="update(false)"
          >
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
    </div>
</template>

<script>
export default {
    name: "schedule",
    props: ['closed', 'schedule', 'value'],
    computed: {
      scheduleSortedDays() {
        let sortable = [];

        for (let day in this.schedule) {
            sortable.push(this.schedule[day]);
        }
        
        return sortable.sort(function (a, b) {
          if ( a.dayNumber < b.dayNumber ){
            return -1;
          }
          if ( a.dayNumber > b.dayNumber ){
            return 1;
          }
          return 0;
        });
      }
    },
    mounted() {
    },
    methods: {
      update(newValue) { 
        this.$store.commit('setOpenSchedule', {
          openSchedule: false
        });
      }
    }
}
</script>