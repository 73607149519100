import { index, getCode, totalIndications } from "@/api/indications";

export default {
    state: {
        indications: [],
        indicatorCode: '',
        totalIndications: 0,
        loading: false,
        errors: {
            message: "",
            errors: ""
        }
    },
    mutations: {
        setIndicatorCode(state, { indicatorCode }) {
            state.indicatorCode = indicatorCode;
        },
        setTotalIndications(state, { totalIndications }) {
            state.totalIndications = totalIndications;
        },
        setIndications(state, { indications }) {
            state.indications = indications;
        },

        setIndicationsLoading(state, { loading }) {
            state.loading = loading;
        },

        setIndicationsErrors (state, { errors }) {
            state.errors = errors;
        }
    },
    actions: {
        // commit, rootState
        async getIndications({ commit }) {
            try {
                commit('setIndicationsLoading', { loading: true });

                let { data } = await index();

                commit('setIndications', { indications: data?.indications });
            } catch (error) {
                let response = error.response.data;
                commit("setIndicationsError", { errors: response });
            } finally {
                commit('setIndicationsLoading', { loading: false });
            }
        },
        async getIndicatorCode({ commit }) {
            try {
                commit('setIndicationsLoading', { loading: true });

                let { data } = await getCode({});

                commit('setIndicatorCode', { indicatorCode: data?.code });
            } catch (error) {
                let response = error.response.data;
                commit("setIndicationsError", { errors: response });
            } finally {
                commit('setIndicationsLoading', { loading: false });
            }
        },
        async getTotalIndications({ commit }) {
            try {
                commit('setIndicationsLoading', { loading: true });

                let { data } = await totalIndications({});
                commit('setTotalIndications', { totalIndications: data?.total });
            } catch (error) {
                let response = error.response.data;
                commit("setIndicationsError", { errors: response });
            } finally {
                commit('setIndicationsLoading', { loading: false });
            }
        }
    }
}