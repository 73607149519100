export default {
    formatMoney: (value) => {
        return 'R$ ' + parseFloat(value / 100).toLocaleString("pt-br", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    },
    formatDate: (date) => {
        return (new Date(date)).toLocaleDateString("pt-BR");
    },
    isAvailableOnStock: (product) => {
        let stock = parseFloat(product.stock);

        return product.stock_logic && stock > 0 || !product.stock_logic;  
    },
    translatePaymentMethod: (paymentMethod) => {
        let methods = {
            'credit_card': 'Cartão de Crédito',
            'pix': 'Pix',
            'money': 'Dinheiro',
        };

        return methods[paymentMethod] ?? '';
    },
    translateDayName: (day) => {
        let days = {
            sunday: 'Domingo',
            monday: 'Segunda-feira',
            tuesday: 'Terça-feira',
            wednesday: 'Quarta-feira',
            thursday: 'Quinta-feira',
            friday: 'Sexta-feira',
            saturday: 'Sábado',
        }

        return days[day];
    },
    formatDateToTime: (date) => {
        let d = new Date(date);
        let h = (d.getHours()<10?'0':'') + d.getHours();
        let m = (d.getMinutes()<10?'0':'') + d.getMinutes();

        return `${h}:${m}`;
    },
    getDayName: (dateStr) => {
        var date = new Date(dateStr);
        return date.toLocaleDateString('en-US', { weekday: 'long' }).toLowerCase();        
    },
    formatDateToHuman: (date) => {
        return (new Date(date)).toLocaleDateString("pt-BR", {
            weekday: 'short',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        });
    },
    getCouponDiscount: (coupon, totalAmount = 0) => {
        if (!coupon) return 0;
        
        if (coupon.type === 'fixed') {
            return coupon.discount_value;
        } else {
            let originalDiscountValue = coupon.discount_value / 100;
            
            return (totalAmount * originalDiscountValue) / 100;
        }
    },
    getOrderStatus: (status, hasAddress) => {
        let orderStatus = {};

        switch (status) {
            case 'pending':
                orderStatus = {
                    icon: 'mdi-dots-horizontal-circle',
                    text: 'pendente',
                }
                break;
            case 'accepted':
                orderStatus = {
                    icon: 'mdi-check-circle-outline',
                    text: 'está sendo preparado',
                }
                break;
            case 'on_the_way':
                orderStatus = {
                    icon: hasAddress ? 'mdi-moped' : 'mdi-transit-transfer',
                    text: hasAddress ? 'a caminho' : 'disponível para retirada',
                }
                break;
            case 'rejected':
                orderStatus = {
                    icon: 'mdi-close-circle',
                    text: 'rejeitado',
                }
                break;
            case 'cancelled':
                orderStatus = {
                    icon: 'mdi-cancel',
                    text: 'cancelado',
                }
                break;
            case 'delivered':
                orderStatus = {
                    icon: 'mdi-checkbox-marked-circle',
                    text: 'entregue',
                }
                break;
            case 'finished':
                orderStatus = {
                    icon: 'mdi-checkbox-marked-circle',
                    text: 'finalizado',
                }
                break;
        }

        return orderStatus;
    },
    getPromotionDiscount(promotion, totalAmount = 0) {
        if (!promotion) return 0;
        
        if (promotion.discount_type === 'fixed') {
            return promotion.discount_value;
        } else {
            let originalDiscountValue = promotion.discount_value / 100;
            
            return (totalAmount * originalDiscountValue) / 100;
        }
    },
    promotionText: (promotion) => {
        let text = '';
        let compositions = promotion
            .compositions;

        compositions.forEach((composition) => {
           text += `${composition.name}: `;
 
           if (composition.condition == 'except') {
            text += `Qualquer ${composition.name.toLowerCase()} exceto ` + composition.option.join(', ') + '<br/>';
           } else {
            text += composition.option.join(', ') + '<br/>';
           }
        });

        return text;
    },
    promotionDiscountText: (promotion) => {
        if (promotion.discount_type == 'percentage') {
            return `${promotion.discount_value / 100}%`;
        } else {
            return `${'R$ ' + (promotion.discount_value / 100)}`; endif; endif;
        }
    },
    getProductPrice(product) {
        return product.price || product?.compositions[0]?.options[0]?.additional_price || 0;
    },
    pluck(array, propertyName) {
        var result = [];
        for (var i = 0; i < array.length; i++) {
          if (array[i].hasOwnProperty(propertyName)) {
            result.push(array[i][propertyName]);
          }
        }
        return result;
    }
}