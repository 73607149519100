import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#2ADFB4',
                secondary: '#2ADFB4',
                danger: '#ed2828',
                gray: '#7a7a7a',
                dark: '#000',
            }
        }
    },
    options: {
        customProperties: true
    }
});
