<template>
    <v-card
        :outlined="true"
        elevation="3"
        class="mt-2"
        style="height: 100%;"
        @click="goTo(`pedidos/${order.id}`)"
    >
        <v-layout class="d-flex flex-column" style="height: 100%;">
            <div class="pa-3 d-flex flex-column">
                <span>
                    <v-icon style="font-size: 16px;">{{ status.icon }}</v-icon>
                    Pedido {{ status.text }} - {{ order.hash }}
                </span>
                <ul class="list">
                    <li v-for="(product, i) in order.products" :key="i">
                        {{ product.name }}
                    </li>
                </ul>
            </div>
            <div class="d-flex align-end" style="height: 100%;">
                <v-btn color="#fff" border="none" block @click.stop="addToBag(order)">
                    Adicionar à sacola
                </v-btn>
            </div>
        </v-layout>
    </v-card>
</template>

<script>
export default {
    name: 'orderCard',
    props: ['order'],
    data() {
        return {
        }
    },
    computed: {
        status() {
            return this.getOrderStatus(this.order.status);
        },
    },
    methods: {
        goTo(to){
            if (this.$route.path === to) return;

            if (to === 'sair') {
                this.logout();
                return;
            }
            
            this.$router.push(to);
        },
        addToBag(order) {
            let self = this;
            self.$store.commit('setBag', { bag: [] });

            let items = order.products.map(function (product) {
                product.compositions = product.selectedComposition;
                product.observations = product.observation;
                product.totalPrice = product.final_price;

                self.$store.commit('addItemToBag', {
                    item: product
                });

                return product;
            });

            this.goTo('/sacola');
        }
    }
}
</script>

<style scoped>
.list {
    color: #888;
    padding-left: 21px;
}

.list li {
    padding: 0px;
}
</style>