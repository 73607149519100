import { find } from "@/api/theme";
import { getDomain } from "@/utils/helpers";

export default {
    state: {
        theme: null,
        loading: false,
        openSchedule: false,
        errors: {
            message: "",
            errors: ""
        }
    },
    mutations: {
        initialiseTheme(state) {
			if (!localStorage.getItem('theme')) {
                return false;
			}

            let theme = JSON.parse(localStorage.getItem('theme'));

            if (theme.domain !== getDomain()) {
                localStorage.removeItem('theme');
                return false;
            }

            state.theme = theme;
		},

        setTheme(state, { theme }) {
            state.theme = theme;
        },

        setOpenSchedule(state, { openSchedule }) {
            state.openSchedule = openSchedule;
        },

        setThemeLoading(state, { loading }) {
            state.loading = loading;
        },

        setThemeErrors (state, { errors }) {
            state.errors = errors;
        }
    },
    actions: {
        // commit, rootState
        async getTheme({ commit }) {
            try {
                commit('setThemeLoading', { loading: true });

                let { data } = await find();
                data.domain = getDomain();

                localStorage.setItem('theme', JSON.stringify(data));

                commit('setTheme', { theme: data });
            } catch (error) {
                let response = error.response.data;
                commit("setThemeError", { errors: response });
            } finally {
                commit('setThemeLoading', { loading: false });
            }
        },
    }
}