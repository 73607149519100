<script>
import Auth from '@/components/layouts/Auth';
import AddressForm from '@/components/forms/Address'
import { mapActions, mapState } from 'vuex';

export default {
    name: "new-address",
    components: {
        Auth,
        AddressForm
    },
    data() {
        return {
            address: {
                name: "",
                zipcode: "",
                state: "",
                city: "",
                district: "",
                complement: "",
                number: "",
            },
        }
    },
    computed: {
        ...mapState({
            theme: (state) => state.theme.theme,
            loading: state => state.addresses.loading,
            errors: state => state.addresses.errors
        })
    },
    methods: {
        async submit() {
            try {
                await this.storeAddress({ form: this.address });
                this.$toast.success("Endereço cadastrado com sucesso!");
                this.$router.replace({ name: 'addresses-index' });
                await this.getAddresses({ search: null });
            } catch (error) {
                this.$toast.error("Houve um erro ao tentar cadastrar endereço!");
            }
        },
        ...mapActions(["storeAddress", "getAddresses"])
    }
}
</script>

<template>
    <v-container>
        <v-layout align-center justify-center="" class="mt-15">
            <v-flex>
                <address-form
                    v-model="address"
                    :theme="theme"
                    :loading="loading"
                    :errors="errors"
                    action="create"
                    @submit="submit"></address-form>
            </v-flex>
        </v-layout>
    </v-container>
</template>