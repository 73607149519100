<template>
  <div class="d-md-flex justify-md-center">
    <v-layout style="max-width: 1400px" column class="mt-5">
      <page-title title="sacola"></page-title>
      <v-container class="mt-10">
        <v-row>
          <v-col
            v-for="(product, i) in bagProducts"
            :key="i"
            cols="12"
            sm="12"
            md="6"
            xl="4"
          >
            <bag-product-card @deleted="removeItem" :product="product" />
          </v-col>
        </v-row>
        <div class="d-flex justify-center mt-5 text-upper">
          <a class="clear-text" @click="clearBag">Limpar sacola</a>
        </div>
        <div class="mt-5">
          <span class="font-weight-bold">Cupom</span>
          <v-text-field
            label="Digite o código"
            dense
            v-model="couponCode"
            @input="findCoupon"
            :disabled="couponLoading"
            :loading="couponLoading"
            single-line
            rounded
            outlined
          ></v-text-field>
        </div>
        <price-summary
          :total="total"
          :table="table"
          :tax="tax"
          :theme="theme"
          :cashbackToUse="cashbackToUse"
          :themeLoading="themeLoading"
          :coupon="coupon"
        />
        <div style="margin-top: 20px">
          <cashback-dialog
            v-if="user?.credits && !table"
            :cashbackToUse="cashbackToUse"
            :credits="user?.credits"
            :disabled="blockedCashback || (!theme?.cashback_settings?.cashback_rules?.cashback_with_promotion && hasPromotion)"
            :total="total + tax - getCouponDiscount(coupon, total + tax)"
            @use="useCashback"
            @cancel="cashbackToUse = 0" />
        </div>
        <div style="margin-top: 20px">
          <v-btn
            color="primary"
            dark
            :loading="couponLoading || themeLoading"
            @click="goToCheckout"
            rounded
            block
          >
            FINALIZAR COMPRA
          </v-btn>
        </div>
      </v-container>
    </v-layout>
  </div>
</template>

<script>
import { VMoney } from "v-money";
import BagProductCard from "../components/cards/BagProductCard";
import PriceSummary from "../components/PriceSummary";
import PageTitle from "../components/layouts/PageTitle";
import CashbackDialog from "../components/modals/CashbackDialog";
import _debounce from "lodash/debounce";
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      slug: "",
      newTax: null,
      email: "",
      couponCode: "",
      password: "",
      cashbackToUse: 0,
      blockedCashback: false,
      showPassoword: false,
      pagament: "",
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        precision: 2,
        masked: false,
      },
    };
  },
  async created() {
    let cashbackIsActive = this.theme?.active_cashback;

    if (this.bagProducts.length == 0) {
      this.goTo("/");
    }

    if (this.coupon) {
      this.couponCode = this.coupon.code;
    }

    if (this?.theme?.district_tax && (this?.user?.current_address[0] ?? false) && this?.user?.current_address[0]?.tax) {
      this.newTax = this?.user?.current_address[0]?.tax;
    }

    if (cashbackIsActive && (!this.theme?.cashback_settings?.cashback_rules?.cashback_with_promotion && this.hasPromotion)) {
      this.$toast.info("Função de cashback inativada, não é possível usar cashback em uma compra com promocão");
      this.cashbackToUse = 0;
    }

    await this.getTheme();
  },
  computed: {
    total() {
      return Math.ceil(
        this.bagProducts.reduce(
          (acc, { compositions, totalPrice, price, quantity }) => {
            return acc + totalPrice * (quantity ?? 1);
          },
          0
        )
      );
    },
    hasPromotion() {
      return this.bagProducts.some((bagProduct) => bagProduct?.hasActivePromotion);
    },
    tax() {
      if ((!this.theme?.tax && !this?.newTax) || this.table) {
        return 0;
      }

      let tax = this?.newTax && this?.newTax > 0 ? this?.newTax : this?.theme?.tax;

      let hasFreeRateProductBonus = this.bagProducts.findIndex(
        (bagProduct) =>
          bagProduct?.free_rate_quantity &&
          (bagProduct?.quantity ?? 0) >= (bagProduct?.free_rate_quantity ?? 0)
      );

      if (hasFreeRateProductBonus >= 0) {
        return 0;
      }

      return this.theme?.free_rate_from &&
        this.theme?.free_rate_from <=
          this.total -
            this.getCouponDiscount(this.coupon, this.total + tax)
        ? 0
        : tax;
    },
    ...mapState({
      bagProducts: (state) => state.bag.bag,
      table: (state) => state.tables.table,
      user: (state) => state.auth.user,
      coupon: (state) => state.coupons.coupon,
      theme: (state) => state.theme.theme,
      themeLoading: (state) => state.theme.loading,
      couponLoading: (state) => state.coupons.loading,
    }),
  },
  directives: { money: VMoney },
  components: {
    BagProductCard,
    CashbackDialog,
    PriceSummary,
    PageTitle,
  },
  methods: {
    useCashback(amount) {
      this.cashbackToUse = amount;
    },
    goToCheckout() {
      if (this.theme.closed) {
        this.$toast.error("O estabelecimento se encontra fechado");
        return false;
      }

      if (this.theme?.only_table && !this.table) {
        this.$toast.error("Apenas pedidos presencial");
        return false;
      }

      this.$store.commit('setSummary', {
        total: this?.total,
        tax: this?.tax,
        cashbackToUse: this?.cashbackToUse,
        coupon: this?.coupon
      });

      this.goTo("/checkout");
    },
    goTo(path) {
      this.$router.push(`${path}`);
    },
    async makeOrder() {
      let order = {};
      order["company"] = this.slug;
      order["products"] = this.bagProducts;
      await this.storeOrder({ order });
    },
    findCoupon: _debounce(async function () {
      await this.getCoupon({ code: this.couponCode });

      if (this.coupon) {
        if (this.total < this.coupon.minimum_value) {
          this.$toast.error("Valor insuficiente para aplicação do cupom");
          this.$store.commit("setCoupon", { coupon: null });
  
          return false;
        }

        let cashbackIsActive = this.theme?.active_cashback;
        let canUseCouponWithCashback = this.theme?.cashback_settings?.cashback_rules?.cashback_with_coupon;

        if (cashbackIsActive && !canUseCouponWithCashback && this.user?.credits) {
          this.$toast.info("Função de cashback inativada, não é possível usar cashback e cupom ao mesmo tempo");
          this.cashbackToUse = 0;
          this.blockedCashback = true;
        }

        this.$toast.success("Cupom aplicado");
      } else {
        this.$toast.error("Cupom inválido");
        this.blockedCashback = false;
      }
    }, 1500),
    removeItem(productId) {
      // TODO: Olhar essa lógica de remover items para impedir que remova o mesmo items com configurações diferentes
      this.$store.commit("removeItemFromBag", {
        productId,
      });

      if (this.bagProducts.length == 0) {
        this.goTo("/");
      }
    },
    clearBag() {
      this.$store.commit("setBag", { bag: [] });
      this.goTo("/");
    },
    ...mapActions(["getCoupon", "getTheme"]),
  },
};
</script>

<style>
.clear-text {
  font-size: 16px;
  text-decoration: underline;
}
</style>