<template>
  <div
    class="notranslate"
    translate="no">
    <v-sheet v-if="loading">
      <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
    </v-sheet>
    <template v-else>
      <div class="d-md-flex justify-md-center mt-md-10">
        <div style="position: relative" class="mt-md-8 mr-md-10">
          <custom-carousel :images="product?.images?.length > 0 ? product.images : [product.imagem]" />
        </div>
        <div class="flex-grow-1 mb-5">
          <div
            v-if="product.free_rate_quantity"
            style="background: #dd1c1e; font-size: 12px;"
            class="py-2 mt-md-8 text-center white--text text-uppercase"
          >
            Na compra de
            <span class="font-weight-bold"
              >{{ product.free_rate_quantity }} unidades</span
            >
            deste produto, o <span class="font-weight-bold">frete é grátis</span>
          </div>
          <div
            v-if="product.use_promotional_quantity && product.promotional_price"
            style="background: #dd1c1e; font-size: 12px;"
            class="text-center white--text py-1 text-uppercase mt-md-8"
          >
            <span v-if="product.measure_unit === 'un'">
              *Ao comprar a partir de
              <span class="font-weight-bold">{{ parseInt(product.promotional_quantity) }} unidades</span> o preço unitário fica 
              <span class="font-weight-bold">{{ formatMoney(product.promotional_price) }}</span>
            </span>
            <span v-else>
              *Ao comprar a partir de
              <span class="font-weight-bold">{{ product.promotional_quantity }} kg</span> o preço kilograma fica 
              <span class="font-weight-bold">{{ formatMoney(product.promotional_price) }}</span>
            </span>
          </div>
          <promotions-dialog
            v-if="product.has_promotions"
            :promotions="product.promotions"
            :theme="theme"
            v-model="promotionsDialog"
          />
          <v-container class="d-flex justify-space-between align-start">
            <div class="text-left">
              <div class="text-uppercase title mt-3">
                {{ product.name }}
              </div>
              <div>
                <p>{{ product.tags ? product.tags.join(', ') : '' }}</p>
              </div>
            </div>
            <div v-if="product.measure_unit === 'kg'" class="d-flex flex-column justify-end align-end">
              <span>Selecionar quantidade (kg)</span>
              <v-text-field
                v-model="quantity"
                label="Quantidade"
                style="width: 140px;"
                ref="kilo"
                type="number"
                min="1"
                :max="product.stock_logic ? product.stock : null"
                single-line
                oninput="if(this.max && Number(this.value) > Number(this.max)) this.value = this.max;"
                rounded
                dense
                suffix='kg'
                outlined
                ></v-text-field>
            </div>
            <div v-else class="d-flex align-center">
              <v-btn
                :disabled="quantity <= 1"
                color="primary"
                small
                class="quantity-btn left"
                @click="subQuantity"
              >
                -
              </v-btn>
              <label style="font-size: 22px" class="mx-2">
                {{ quantity }}
              </label>

              <v-btn
                small
                :disabled="product.stock_logic && quantity >= product.stock ? true : false"
                color="primary"
                class="quantity-btn right"
                @click="addQuantity"
              >
                +
              </v-btn>
            </div>
          </v-container>
          <v-container class="text-center caption mt-2" style="width: 70%">
            <div
              v-if="product.preparation_time_label"
              class="d-flex justify-space-between"
            >
              <div
                class="d-flex flex-column align-start"
                v-if="product.price > 0"
              >
                <span :class="hasActivePromotion ? 'success--text font-weight-bold' : 'font-weight-bold'">Preço {{ product?.measure_unit == 'kg' ? 'kilograma' : 'unitário'  }}</span>
                <span
                  class="title font-weight-bold primary--text"
                  >{{ formatMoney(total) }}</span
                >
              </div>
              <div style="border-right: 1px solid #00c89e"></div>
              <div class="d-flex flex-column align-center text-center">
                <span class="font-weight-bold">Tempo estimado</span>
                <span
                  class="title font-weight-bold primary--text"
                  >{{ product.preparation_time_label }}</span
                >
              </div>
            </div>
            <div v-else class="d-flex justify-center">
              <div
                class="d-flex flex-column align-start"
                v-if="product.price > 0"
              >
                <span class="font-weight-bold">Preço {{ product?.measure_unit == 'kg' ? 'kilograma' : 'unitário'  }}</span>
                <div v-if="(product.promotions[0] ?? null) && product.promotions[0].compositions.length == 0">
                  <v-chip v-if="product.promotions[0].discount_type !== 'fixed'" class="mr-1" color="success" x-small>
                    -{{ product.promotions[0].discount_value / 100 }}%
                  </v-chip>
                  <span style="font-size: 14px;">De: <s>{{ formatMoney(getProductPrice(product)) }}</s></span><br />
                  <span
                    :class="`title font-weight-bold ${product.use_promotional_quantity && product.promotional_quantity && quantity >= product.promotional_quantity ? 'success--text' : 'primary--text'}`"
                    >Por: {{ formatMoney(getProductPrice(product) - getPromotionDiscount(product.promotions[0], product.price)) }}</span
                  >
                </div>
                <div v-else>
                  <span
                    :class="`title font-weight-bold ${product.use_promotional_quantity && product.promotional_quantity && quantity >= product.promotional_quantity ? 'success--text' : 'primary--text'}`"
                    >{{ formatMoney(total) }}</span
                  >
                </div>
              </div>
            </div>
          </v-container>
          <composition
            v-show="product.compositions?.length > 0"
            :compositions="product.compositions"
            :promotions="product.promotions"
            :model.sync="composition"
          />
          <v-layout column class="mt-5 mx-3" style="height: 230px">
            <v-textarea
              outlined
              id="observation"
              name="input-7-4"
              v-model="observations"
              color="primary"
              label="Observações"
            ></v-textarea>
          </v-layout>
        </div>
        <div class="d-flex align-center add-to-cart">
          <span
            class="mb-5 flex-grow-1"
            style="white-space: nowrap; font-size: 20px"
          >
            <span class="font-weight-bold">Total: </span>
            <span
              :class="hasActivePromotion ? 'success--text' : ''"
              >{{ formatMoney(totalPrice * quantity) }}</span
            >
          </span>
          <v-btn
            id="add-to-cart"
            class="mb-5 notranslate"
            color="primary"
            translate="no"
            :disabled="this.hasSelectedAllCompositions || quantity <= 0"
            style="width: 100%; max-width: 200px"
            @click="addToBag(composition, observations)"
          >
            Adicionar
          </v-btn>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Composition from '@/components/Composition.vue';
import CustomCarousel from '@/components/slides/Carousel.vue';
import PromotionsDialog from '@/components/modals/PromotionsDialog.vue';
import { mapActions, mapState } from 'vuex';

export default {
    name:'product',
    data(){
      return {
        productId: this.$route.params.id,
        promotionsDialog: false,
        quantity: 1,
        limitExceeded: false,
        observations: "",
        composition: {},
        kilogram: {
          decimal: ',',
          thousands: '.',
          prefix: 'R$ ',
          suffix: ' #',
          precision: 2,
          masked: false /* doesn't work with directive */
        }
      }
    },
    async created() {
        if (this.product.stock_logic && this.product.stock <= 0) {
            this.$router.replace('/');
        }
        
        if (this.product?.id != this.productId) {
            await this.findProduct({ 
                productId: this.productId
            });    
        }

    },
    computed: {
      ...mapState({
        theme: (state) => state.theme.theme,
        product: state => state.products.product,
        loading: state => state.products.loading
      }),
      hasSelectedAllCompositions() {
        if (!this.product?.compositions) return false;

        let selectedComposition = this.composition;
        let blocked = false;

        this.product.compositions.forEach(function (composition) {
          let totalSelected = selectedComposition[composition.name] ? selectedComposition[composition.name]?.length ?? 1 : 0;

          if (
            composition?.min_selection &&
            totalSelected < composition.min_selection
          ) {
            blocked = true;
          }
        });

        return blocked;
      },
      totaPriceWithoutPromotion() {
        let total = this.product.price;
        let selectedValues = [];
        let selectedValue = null;
        let compositionWithMaxValue = this?.product?.compositions?.filter((item) => item.use_max_value_as_price);

        for (let propName in this.composition) {
          let obj = this.composition[propName];
          let isCompositionWithMaxValue = (compositionWithMaxValue ?? [])?.find((item) => item.name == propName);
          
          if (obj?.constructor?.name === 'Array') {
            total += obj.reduce(function (acc, comp) {
              selectedValue = parseInt(comp.additional_price ?? 0);
              selectedValues.push(selectedValue);
              return acc + selectedValue;
            }, 0);
          } else {
            selectedValue = parseInt(obj?.additional_price ?? 0);
            selectedValues.push(selectedValue);
            total += selectedValue;
          }

          if (isCompositionWithMaxValue) {
            total = Math.max(...selectedValues);
          }
        }

        return total;
      },
      total() {
        let activePromotion = this.hasActivePromotion;

        if (activePromotion) {
          let price = 0;

          if (activePromotion.discount_type == 'final_price') {
            price = activePromotion.discount_value;
            return price;
          } else {
            // price = this.getPromotionDiscount(activePromotion, this.product.price);
          }
        }

        if (this.product.use_promotional_quantity && this.product.promotional_quantity && this.quantity >= this.product.promotional_quantity) {
          return this.product.promotional_price;
        }

        return this.product.price;
      },
      totalPrice() {
        let total = this.total;
        let selectedValues = [];
        let selectedValue = null;
        let compositionWithMaxValue = this?.product?.compositions?.filter((item) => item.use_max_value_as_price);

        for (let propName in this.composition) {
          let obj = this.composition[propName];
          let isCompositionWithMaxValue = (compositionWithMaxValue ?? [])?.find((item) => item.name == propName);
          
          if (obj?.constructor?.name === 'Array') {
            total += obj.reduce(function (acc, comp) {
              selectedValue = parseInt(comp.additional_price ?? 0);
              selectedValues.push(selectedValue);
              return acc + selectedValue;
            }, 0);
          } else {
            selectedValue = parseInt(obj?.additional_price ?? 0);
            selectedValues.push(selectedValue);
            total += selectedValue;
          }

          if (isCompositionWithMaxValue) {
            total = Math.max(...selectedValues);
          }
        }

        let activePromotion = this.hasActivePromotion;

        if (activePromotion) {
          if (activePromotion.discount_type == 'final_price') {
            total = activePromotion.discount_value;
          } else {
            total -= this.getPromotionDiscount(activePromotion, total);
          }
        }

        return total;
      },
      hasActivePromotion() {
        let activePromotion = false;

        for (let index in this.product.promotions) {
          let promotion = this.product.promotions[index];
          activePromotion = promotion;

          promotion.compositions.forEach((composition) => {
            let selectedComposition = this.composition[composition.name];
            let selectedOptions = null;

            if (selectedComposition) {
              if (selectedComposition instanceof Array) {
                selectedOptions = selectedComposition.map((item) => item.name);
                let includesOption = composition.option.some((item) => selectedOptions.includes(item));

                if (composition.condition == 'except' && includesOption) {
                  activePromotion = false;
                }

                if (composition.condition == 'including' && !includesOption) {
                  activePromotion = false;
                }
              } else {
                let includesOption = composition.option.includes(selectedComposition.name);

                if (composition.condition == 'except' && includesOption) {
                  activePromotion = false;
                }

                if (composition.condition == 'including' && !includesOption) {
                  activePromotion = false;
                }
              }
            }
          })

          if (activePromotion) {
            break;
          }
        }

        return activePromotion;
      },
    },
    watch: {
      quantity: function (newQuantity, oldQuantity) {
        let newQuantityf = parseFloat(newQuantity);
        let showMessageBool = this.product.measure_unit == 'kg' ? newQuantityf > this.product.stock : newQuantityf >= this.product.stock;

        if (this.product.stock_logic && showMessageBool) {
          this.$toast.info('Limite disponível em estoque atingido');
        }
      }
    },
    components: {
      Composition,
      PromotionsDialog,
      CustomCarousel
    },
    methods: {
      addToBag(composition, observations) {
        let newProduct = { ...this.product };
        newProduct.quantity = this.quantity;
        newProduct.compositions = composition;
        newProduct.totalPriceWithoutPromotion = this.totaPriceWithoutPromotion;
        newProduct.totalPrice = this.totalPrice;
        newProduct.observations = observations;
        newProduct.hasActivePromotion = this.hasActivePromotion;
        
        this.$store.commit('addItemToBag', {
          item: newProduct
        });
        this.$router.replace(`/`);
      },
      subQuantity() {
        if (this.quantity == 1) return;
        
        this.quantity--;
      },
      addQuantity() {
        this.quantity++;
      },
      ...mapActions(['findProduct'])
    }
}
</script>

<style scoped>
.page-title {
    position: relative;
}

.page-title::before {
    content: "";
    position: absolute;
    top: 38px;
    left: 50%;
    background: var(--v-secondary);
    transform: translate(-50%, 0%);
    width: 30%;
    max-width: 200px;
    height: 2px;
}

.quantity-btn {
    border: 0px;
    color: #fff;
    width: 5px;
    font-size: 18px;
    height: 25px !important;
    min-width: 0px !important;
}

.quantity-btn.left {
    border-radius: 100px 0px 0 100px;
    font-size: 25px;
}

.quantity-btn.right {
    border-radius: 0px 100px 100px 0px;
}

.add-to-cart {
  background: #fff;
  position: fixed;
  bottom: 0px;
  width: 100%;
  max-width: 1400px;
  padding: 0px 10px;
  padding-top: 20px;
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.25);
}
</style>