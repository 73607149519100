<template>
  <v-btn-toggle rounded :value="value" @change="update" borderless mandatory>
    <template v-if="table">
      <v-btn value="withdraw">
        <span>{{ table?.name }}</span>
  
        <v-icon right> mdi-table-chair </v-icon>
      </v-btn>
    </template>
    <template v-else>
      <v-btn value="delivery" v-if="theme?.form?.show_address_fields !== false">
        <span>Entregar</span>
  
        <v-icon right> mdi-moped </v-icon>
      </v-btn>
  
      <v-btn value="withdraw">
        <span>Retirada</span>
  
        <v-icon right> mdi-transit-transfer </v-icon>
      </v-btn>
    </template>
  </v-btn-toggle>
</template>

<script>
export default {
  name: "WithdrawOption",
  props: ["value", "table", "theme"],
  methods: {
    update(newValue) { this.$emit('input', newValue); },
  }
};
</script>