<template>
  <v-app :style="cssProps">
    <vue-splash
      :style="`${theme?.logoBackground ? 'color: #fff;' : ''}`"
      :show="theme && loadingSplash"
      :logo="theme ? theme.logo : 'https://meodelivery.s3.amazonaws.com/Ativo+4meo_delivery.png'"
      :title="theme?.siteName"
      color="#00bfa5"
      :size="150"
      :fixed="true"
      :background-color="theme?.logoBackground ? theme?.themeColor1 : '#fff'"
    />
    <drawer v-if="theme && !loadingSplash">
      <v-main>
        <transition name="fade" mode="out-in">
          <content-centered>
            <router-view/>
            <generic-dialog
              :title="table?.name"
              :open="tableDialog"
              @close="tableDialog = false"
            >
              <span>Seja bem vindo 😊</span> <br />
              <span>
                Você está na <span class="font-weight-bold">{{ table?.name }}</span>
              </span> <br />
              <span>Por favor, faça seu pedido e relaxe enquanto nosso atendente se prepara para atendê-lo.</span>
            </generic-dialog>
          </content-centered>
        </transition>
      </v-main>
    </drawer>
    <not-found v-if="!theme && !loadingSplash"/>
  </v-app>
</template>

<script>

import Drawer from '@/components/navigation/Drawer';
import GenericDialog from '@/components/generics/GenericDialog';
import ContentCentered from './components/layouts/ContentCentered.vue';
import NotFound from './views/404.vue';
// import Favicon from '@/components/Favicon.vue';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'App',

  components: {
    ContentCentered,
    NotFound,
    GenericDialog,
    Drawer
  },

  async created() {
    if (!this.theme) {
      this.loadingSplash = true;
      await this.getTheme();
      this.loadingSplash = false;
    }

    if (this.theme) {
      this.setThemeColor(this.theme);
      document.title = this.theme?.siteName;
      let tabCode = this.$route?.query?.tab ?? null;

      let element = document.querySelector('#manifest-placeholder');
      var link = document.querySelector("link[rel~='icon']");
      let ogTitle = document.querySelector('meta[property="og:title"]');
      let ogImage = document.querySelector('meta[property="og:image"]');
      let ogUrl = document.querySelector('meta[property="og:url"]');

      if (tabCode) {
        await this.findTable({ tableCode: tabCode});

        if (this.table) {
          this.tableDialog = true;
        }
      }

      ogTitle.content = this.theme?.siteName;

      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.head.appendChild(link);
      }
      link.href = this.theme.favico;

      element.setAttribute('href', this.theme?.manifest);

      if (this.theme?.facebook_pixel) {
        const pixelScript = document.createElement('script');

        // Set the script content
        pixelScript.innerHTML = `
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '${this.theme?.facebook_pixel}');
          fbq('track', 'PageView');
        `;

        // Insert the script into the <head> of the webpage
        document.head.appendChild(pixelScript);
      }
    }
  },

  async mounted() {
    if (this.user) {
      await this.getAuthenticatedUser();
    }
  },

  data: () => ({
    loadingSplash: false,
    tableDialog: false
  }),

  computed: {
    ...mapState({
      theme: state => state.theme.theme,
      table: state => state.tables.table,
      user: (state) => state.auth.user,
    }),
    cssProps () {
      var themeColors = {}
      Object.keys(this.$vuetify.theme.themes.light).forEach((color) => {
        themeColors[`--v-${color}`] = this.$vuetify.theme.themes.light[color]
      })
      
      return themeColors
    }
  },

  methods: {
    ...mapActions(["findTable", "getTheme", "getAuthenticatedUser"]),
    setThemeColor(theme) {
      this.$vuetify.theme.themes.light.primary = theme.themeColor1;
      this.$vuetify.theme.themes.light.secondary = theme.themeColor2;
    }
  }
};
</script>

<style>
.logo-background {
  background: var(--v-primary);
  border-radius: 50%;
}
</style>