<template>
  <v-layout column class="mt-5">
    <page-title title="Meus cartões"></page-title>
    <div class="container">
      <div>
        <div class="container">
          <v-layout v-if="loading" class="text-center">
            <v-flex>
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-flex>
          </v-layout>
          <div v-else>
            <v-radio-group v-model="currentCardId" @change="changedCurrentCard">
              <v-row>
                <v-col v-for="(card, i) in cards" :key="i" cols="12" sm="12" md="6" xl="3">
                  <credit-card :theme="theme" :card="card" @destroy="destroy" />
                </v-col>
              </v-row>
            </v-radio-group>
          </div>
          <div v-if="!loading && cards?.length == 0" class="text-center">
            <v-icon color="primary" size="50">mdi-alert-circle-outline</v-icon>
            <span class="font-weight-bold">Você ainda não possui cartões cadastrados</span>
          </div>
        </div>
      </div>
      <div>
        <v-btn
          class="mt-3"
          color="primary"
          dark
          @click="goTo('/novo-cartao')"
          rounded=""
          block
        >
          Adicionar
        </v-btn>
      </div>
    </div>
  </v-layout>
</template>

<script>
import CreditCard from '@/components/cards/CreditCard';
import PageTitle from "@/components/layouts/PageTitle";
import CardForm from "@/components/forms/Card";
import { mapActions, mapState } from 'vuex';

export default {
  name: "cards",
  components: {
    CreditCard,
    PageTitle,
    CardForm,
  },
  computed: {
    ...mapState({
        theme: (state) => state.theme.theme,
        user: state => state.auth.user,
        cards: state => state.cards.cards,
        loading: state => state.cards.loading,
    })
  },
  data() {
    return {
      currentCardId: "",
    }
  },
  async created() {
    if (this.cards.length == 0) {
      await this.getCards({ search: null });
    }

    this.currentCardId = this.user.current_card[0].id;

  },
  methods: {
    ...mapActions(['getCards', 'destroyCard', 'setCurrentCard']),
    changedCurrentCard() {
      let currentCardId = this.currentCardId;

      let newCard = this.cards
        .filter((card) => card.id == currentCardId);

      this.$store.commit('setCurrentCard', {
        card: newCard
      });

      this.setCurrentCard({ cardId: currentCardId });

      this.$toast.success("Cartão padrão alterado para: " + newCard[0]?.id ?? null);
    },
    destroy(cardId) {
      let newCards = this.cards
        .filter((card) => card.id !== cardId);

      this.$store.commit('setCards', {
        cards: newCards
      });
      
      this.destroyCard({ cardId });
    },
    goTo(to){
      if (this.$route.path === to) return;

      if (to === 'sair') {
        this.logout();
        return;
      }
      
      this.$router.push(to);
    }
  }
}
</script>