<template>
    <auth>
    <v-layout style="max-width: 700px" column class="mt-5">
        <page-title title="inscreva-se"></page-title>
        <v-container class="mt-10">
            <customer-form
                title="Dados pessoais"
                v-model="customer"
                :theme="theme"
                :loading="loading || authLoading"
                :errors="customerErrors"
                action="create"></customer-form>
            <address-form
                v-model="address"
                :theme="theme"
                :loading="loading || authLoading"
                :errors="customerErrors"></address-form>
            <div style="margin-top: 50px;">
                <v-btn
                    class="mt-3"
                    color="primary"
                    dark
                    @click="register"
                    rounded=""
                    :loading="loading || authLoading"
                    block
                >
                    Finalizar cadastro
                </v-btn>
            </div>
        </v-container>
    </v-layout>
    </auth>
</template>

<script>
import Auth from "@/components/layouts/Auth";
import { mapState, mapActions } from "vuex";
import PageTitle from "@/components/layouts/PageTitle";
import CustomerForm from "@/components/forms/Customer";
import AddressForm from "@/components/forms/Address";

export default {
  name: "register",
  components: {
    Auth,
    PageTitle,
    CustomerForm,
    AddressForm,
  },
  data() {
    return {
      customer: {
        name: "",
        email: "",
        birth_date: "",
        phone: "",
        password: "",
        password_confirmation: "",
      },
      address: {
        name: "",
        zipcode: "",
        state: "",
        city: "",
        street: "",
        district: "",
        number: "",
      },
    };
  },
  computed: {
    ...mapState({
      theme: (state) => state.theme.theme,
      loading: (state) => state.customers.loading,
      authLoading: (state) => state.auth.loading,
      customerErrors: (state) => state.customers.errors,
    }),
  },
  methods: {
    ...mapActions(["createCustomer", "login"]),
    async register() {
      let form = {
        ...this.customer,
        address: this.address,
      };

      try {
        await this.createCustomer({ customer: form });

        await this.login({
          email: this.customer.email,
          phone: this.customer.phone,
          password: this.customer.password,
        });

        this.$toast.success(`Bem vindo, ${this.customer.name}`);
      } catch (error) {
        this.$toast.error("Houve um problema com os dados informados");
      }

      // await this.login({
      //     username: this.customer.email,
      //     password: this.customer.password
      // });
    },
  },
};
</script>