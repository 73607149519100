<template>
    <div class="text-center">
        <v-bottom-sheet
            :value="value"
            @input="update"
            inset
            :persistent="loading"
        >
        <v-sheet
            class="text-center"
            style="height: 100%;"
        >
                <v-btn
                    class="mt-6"
                    text
                    color="primary"
                    @click="$emit('close', true)"
                >
                    Cancelar
                </v-btn>
                <div class="py-3 d-flex flex-column px-3" style="height: 100%;">
                    <v-layout v-if="loading" class="text-center">
                        <v-flex>
                            <v-progress-circular
                                indeterminate
                                color="primary"
                            ></v-progress-circular>
                        </v-flex>
                    </v-layout>
                    <div v-else>
                        <v-row>
                            <v-col v-for="(card, i) in cards" :key="i" cols="12" sm="12" md="6" xl="3">
                                <v-card class="pa-2 pb-0" @click="setCard(card.id)" v-ripple="{ center: true }">
                                    <current-card :theme="theme" :card="card" class="text-left" />
                                </v-card>
                            </v-col>
                        </v-row>
                        <div v-if="!loading && cards?.length == 0" class="text-center my-5">
                            <v-icon color="primary" size="50">mdi-alert-circle-outline</v-icon>
                            <span class="font-weight-bold">Você ainda não possui cartões cadastrados</span>
                        </div>
                    </div>
                    <new-card-form-dialog
                        :theme="theme"
                        :user="user"
                        @cardSaved="cardSaved"
                    ></new-card-form-dialog>
                </div>
            </v-sheet>
        </v-bottom-sheet>
    </div>
</template>

<script>
import CurrentCard from '@/components/cards/CurrentCard'
import NewCardFormDialog from '@/components/dialogs/NewCardFormDialog'

export default {
    name: "CardBottomSheet",
    props: ['value','cards', 'loading', 'theme', 'user'],
    components: {
        CurrentCard,
        NewCardFormDialog
    },
    methods: {
      update(newValue) { this.$emit('input', newValue); },
      setCard(newCardId, notify = true) {
        let newCard = this.cards
            .filter((card) => card.id == newCardId);

        this.$store.commit('setCurrentCard', {
            card: newCard
        });

        this.$emit('close', true);

        if (notify) {
            this.$toast.success('Cartão alterado');
        }
      },
      cardSaved(payload) {
        // this.$emit('close', true);
      }
    }
}
</script>