import client from '@/api/client';

export async function index({ search, categoryId, page }) {
    return await client.get(`/products?page=${page}&filter[name]=${search}&filter[category_id]=${categoryId}`);
}

export async function highlights({ search, categoryId }) {
    return await client.get(`/products/highlights`);
}

export async function find({ productId }) {
    return await client.get(`/products/${productId}`);
}