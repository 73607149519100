import { index } from "@/api/districts";

export default {
    state: {
        districts: [],
        loading: false,
        errors: {
            message: "",
            errors: ""
        }
    },
    mutations: {
        setDistricts(state, { districts }) {
            state.districts = districts;
        },

        setDistrictsLoading(state, { loading }) {
            state.loading = loading;
        },

        setDistrictsErrors (state, { errors }) {
            state.errors = errors;
        }
    },
    actions: {
        // commit, rootState
        async getDistricts({ commit }, { search = '' }) {
            try {
                commit('setDistrictsLoading', { loading: true });

                let { data } = await index({ search });

                commit('setDistricts', { districts: data });
            } catch (error) {
                let response = error.response.data;
                commit("setDistrictsError", { errors: response });
            } finally {
                commit('setDistrictsLoading', { loading: false });
            }
        },
    }
}