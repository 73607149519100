export const getDomain = () => {
    let host = window.location.host;
    let parts = host.split('.');
    let firstPart = parts[0] ?? '';
    let currentHost = host.replace(`${firstPart}.`, '');

    if (currentHost === process.env.VUE_APP_BASE_URL) {
        host = `${parts[0]}.${process.env.VUE_APP_API_BASE_URL}`;
    } else {
        let newHost = window.location.host.split(':')[0] ?? false;
        newHost = newHost.replace(/www\.|\.com|\.br|\.app/gi, '');

        if (newHost === 'queijoefrios') {
            newHost = 'queijosefrios';
        }

        host = `${newHost}.${process.env.VUE_APP_API_BASE_URL}`;
    }

    return host;
}