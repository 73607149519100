import router from "@/router";
import { updateProfile, updatePassword, create } from '@/api/customers';
import { user } from '@/utils/auth';

export default {
    state: {
        user: null,
        loading: false,
        errors: {
            message: "",
            errors: ""
        }
    },
    mutations: {
        setCustomersLoading(state, { loading }) {
            state.loading = loading;
        },
        
        setCustomersErrors(state, { errors }) {
            state.errors = errors;
        }
    },
    actions: {
        // commit, rootState
        async createCustomer({ commit, dispatch }, { customer }) {
            try {
                commit("setCustomersLoading", { loading: true });

                let { data } = await create({ customer });

                return data;
            } catch(error) {
                let response = error.response.data;

                response.address = {
                    name: response['address.name'],
                    city: response['address.city'],
                    district: response['address.district'],
                    number: response['address.number'],
                    state: response['address.state'],
                    street: response['address.street'],
                    zipcode: response['address.zipcode'],
                };
                
                commit("setCustomersErrors", { errors: response });
                throw error;
            } finally {
                commit("setCustomersLoading", { loading: false });
            }
        },
        async updateProfile({ commit, dispatch }, { customer }) {
            try {
                commit("setCustomersLoading", { loading: true });

                let { data } = await updateProfile({ customer });

                let user = JSON.parse(localStorage.getItem("user"));
                user.customer = data;

                localStorage.setItem('user', JSON.stringify(user));
            } catch(error) {
                let response = error.response.data;

                commit("setCustomersErrors", { errors: response });
                throw error;
            } finally {
                commit("setCustomersLoading", { loading: false });
            }
        },

        async updatePassword({ commit, dispatch }, { password }) {
            try {
                commit("setCustomersLoading", { loading: true });

                let { data } = await updatePassword({ password });

                return data;
            } catch(error) {
                let response = error.response.data;
                commit("setCustomersErrors", { errors: response });
                throw error;
            } finally {
                commit("setCustomersLoading", { loading: false });
            }
        },
    }
}