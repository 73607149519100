<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <div class="d-flex flex-column justify-end">
          <div>
            <span class="font-weight-medium"> Compre e receba créditos </span>
          </div>
          <v-btn color="success" x-small dark v-bind="attrs" v-on="on">
            como participar
          </v-btn>
        </div>
      </template>

      <v-card>
        <v-card-title class="text-h5 success white--text">
          Como participar
        </v-card-title>

        <v-card-text>
          <div class="mt-5">
            <div class="mb-3">
              <span class="font-weight-bold">Em toda compra</span>
            </div>
            <ul v-for="rule in cashbackRules" :key="rule">
              <li>
                a partir de
                <span class="font-weight-medium">{{
                  formatMoney(rule.cashback_range)
                }}</span>
                Você receberá
                <span class="font-weight-bold">
                  <span v-if="rule.cashback_type === 'fixed'">
                    {{ formatMoney(rule.cashback_value) }} em créditos no aplicativo
                  </span>
                  <span v-else>{{ rule.cashback_value }}% do valor total da compra em créditos no aplicativo</span>
                </span>
              </li>
            </ul>
            <div class="mt-3" v-if="Object.keys(cashbackRules).length > 1">
              <span
                >*Apenas a regra de maior valor será elegida, não sendo
                acumulativo em apenas uma compra</span
              >
            </div>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false"> OK </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
  
<script>
export default {
  name: "cashback-rules-dialog",
  props: ["cashbackRules"],
  data() {
    return {
      dialog: false,
    };
  },
};
</script>