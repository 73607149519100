import client from '@/api/client';

export async function create({ customer }) {
    let form = {
        ...customer
    };

    return await client.post(`/customers`, form);
}

export async function updateProfile({ customer }) {
    let form = {
        ...customer,
        "_method": "PUT"
    };

    return await client.post(`/customer/update-profile`, form, { headers: { "Content-Type": "multipart/form-data" } });
}

export async function updatePassword({ password }) {
    let form = {
        ...password,
        "_method": "PUT"
    };

    return await client.post(`/customer/update-password`, form, { headers: { "Content-Type": "multipart/form-data" } });
}