<template>
  <v-row>
    <v-col
      v-for="(product, i) in products"
      :key="i"
      :cols="theme?.product_display == 'list' ? 12 : 6"
      :md="theme?.product_display == 'list' ? 4 : 6"
      :lg="theme?.product_display == 'list' ? 4 : 2"
      :xl="theme?.product_display == 'list' ? 3 : 2"
    >
      <product-list-card
        v-if="theme?.product_display == 'list'"
        :product="product"
      />
      <product-grid-card
        v-if="theme?.product_display == 'grid'"
        :product="product" 
      />
    </v-col>
  </v-row>
</template>

<script>
import ProductGridCard from "./cards/ProductGridCard";
import ProductListCard from "./cards/ProductListCard";

export default {
  name: "ProductList",
  props: ["products", "theme"],
  components: {
    ProductGridCard,
    ProductListCard,
  },
};
</script>