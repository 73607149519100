<template>
    <v-card
        outlined
        style="border-radius: 15px;"
  >
  <transition name="fade">
        <div class="d-flex" style="overflow: hidden; position: relative; padding: 1px;">
            <v-img
                style="border-radius: 15px 15px 0px 0px; min-width: 130px;"
                class="mr-3"
                lazy-src="@/assets/product-image-placeholder.jpg"
                @error="$event.target.src=require(`@/assets/product-image-placeholder.jpg`)"
                max-width="130"
                :src="product.imagem"
                contain
            ></v-img>
            <div v-if="product.free_rate_quantity" class="ribbon ribbon-top-left ribbon-red"><span>Frete grátis</span></div>
            <v-chip
                v-if="product.hasActivePromotion"
                class="promotion ma-2"
                color="green"
                x-small
            >
                PROMOÇÃO ATIVA
            </v-chip>
            <div class="d-flex flex-column" style="width: 100%; height: 100%;">
                <span class="font-weight-medium" style="font-size: 17px; width: 90%;">{{ product.name }}</span>
                <div style="height: 45px; overflow-y: auto; font-size: 10px;">
                    <span>{{ product.tags ? product.tags.join(', ') : '' }}</span>
                </div>

                <div class="d-flex align-center justify-space-between mt-5">
                    <div v-if="(product.promotions[0] ?? null) && product.promotions[0].compositions.length == 0">
                        <v-chip v-if="product.promotions[0].discount_type !== 'fixed'" class="mr-1" color="success" x-small>
                            -{{ product.promotions[0].discount_value / 100 }}%
                        </v-chip>
                        <span style="font-size: 14px;">De: <s>{{ formatMoney(getProductPrice(product) * product.quantity) }}</s></span><br />
                        <span class="title primary--text">Por: {{ formatMoney(product.totalPrice * product.quantity) }}</span>
                    </div>
                    <div v-else>
                        <span class="title primary--text">{{ formatMoney(product.totalPrice * product.quantity) }}</span>
                    </div>
                    <v-card-actions>
                        <div class="d-flex align-center">
                            <v-btn
                                :color="product.quantity == 1 ? 'danger' : 'primary'"
                                class="quantity-btn left"
                                @click="subQuantity()"
                            >
                                <span v-if="product.quantity > 1">-</span>
                                <v-icon x-small v-else>mdi-delete</v-icon>
                            </v-btn>
                            <span class="quantity-box">
                                <span v-if="product.measure_unit === 'un'">
                                    {{ (product.quantity + '').padStart(2, '0') }}
                                </span>
                                <span v-if="product.measure_unit === 'kg'">{{ product.quantity + '' }}</span>
                                <span v-if="product.measure_unit === 'kg'">kg</span>
                            </span>
                            <v-btn
                                color="primary"
                                :disabled="product.stock_logic && quantity >= product.stock ? true : false"
                                class="quantity-btn right"
                                @click="addQuantity()"
                            >
                            <span>+</span>
                            </v-btn>
                        </div>
                    </v-card-actions>
                </div>
                <v-icon class="trash" color="danger" @click="$emit('deleted', product.id)" x-small>mdi-delete</v-icon>
            </div>
        </div>
    </transition>
    <bag-product-detail
        :product="product"
    />
  </v-card>
</template>

<script>
import BagProductDetail from '@/components/cards/BagProductDetail.vue'

export default {
    name: "ProductCard",
    props: ['product'],
    computed: {
    },
    components: {
        BagProductDetail  
    },
    methods: {
        goToProduct(productId) {
            this.$router.replace(`produto/${productId}`);
        },
        subQuantity() {
            if (this.product.quantity <= 1) {
                this.$emit('deleted', this.product.id);
                return;
            }

            if (this.product.measure_unit === 'kg') {
                this.product.quantity = parseFloat(this.product.quantity) - 0.100;
                this.product.quantity = this.product.quantity.toFixed(2);
            } else {
                this.product.quantity--;
            }
            
        },
        addQuantity() {
            if (this.product.measure_unit === 'kg') {
                this.product.quantity = parseFloat(this.product.quantity) + 0.100;
                this.product.quantity = this.product.quantity.toFixed(2);
            } else {
                this.product.quantity++;
            }
        },
    }
}
</script>

<style scoped>
.quantity-box {
    width: 100%;
    font-size: 10px;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    height: 21px;
    padding: 5px;
    margin-top: 1px;
    padding-top: 2px;
}

.trash {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px !important;
}

.quantity-btn {
    border: 0px;
    color: #fff;
    min-width: 0 !important;
    padding: 0 !important;
    width: 25px !important;
    font-size: 10px;
    height: 20px !important;
    min-width: none !important;
}

.quantity-btn.left {
    border-radius: 100px 0px 0 100px;
}

.quantity-btn.right {
    border-radius: 0px 100px 100px 0px;
}

.promotion {
  color: #fff;
  position: absolute;
  bottom: 0;
  left: calc(2% - 4px);
}
</style>