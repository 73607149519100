<template>
    <div class="text-center">
        <div class="page-title text-uppercase">{{ title }}</div>
    </div>
</template>

<script>
export default {
    name:"page-title",
    props: ['title']
}
</script>

<style scoped>
.page-title {
    position: relative;
}

.page-title::before {
    content: "";
    background: var(--v-secondary);
    position: absolute;
    top: 42px;
    left: 50%;
    transform: translate(-50%, 0%);
    width: 30%;
    max-width: 300px;
    height: 2px;
}
</style>