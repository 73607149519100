<template>
  <div
    class="ml-2 text-position"
  >
    <div style="font-size: 16px; font-weight: bold; white-space: nowrap;" class="text-uppercase">
      {{ theme.siteName }}
    </div>
    <!-- <label style="font-size: 14px; font-weight: 500">
      {{ theme.siteDescription }}
    </label> -->
  </div>
</template>

<script>
export default {
  name: "company-name",
  props: ["theme", "show"],
};
</script>

<style>
.text-position {
  color: #fff;
  transform: translate(0%, 35px);
}

@media (min-width: 768px) {
  .text-position {
    color: #fff;
    transform: translate(0%, 40px);
  }
}
</style>