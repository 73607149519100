<template>
  <div style="transform: translate(0, -30px)">
    <vue-paycard
      style="transform: translate(0, 40px)"
      :value-fields="card"
      :labels="labels"
      :has-random-backgrounds="false"
      :input-fields="{
        cardNumber: 'v-card-number',
        cardName: 'v-card-name',
        cardMonth: 'v-card-month',
        cardYear: 'v-card-year',
        cardCvv: 'v-card-cvv',
      }"
    />
    <v-card class="px-3 py-5 my-3">
      <h2 class="my-5 mt-8">Preencha os dados do cartão</h2>
      <v-form ref="form">
        <v-row>
          <v-col cols="12">
            <v-text-field
              id="email"
              type="email"
              class="py-0 my-0"
              color="primary"
              required
              :value="card.email"
              :disabled="disableEmail"
              :rules="[v => !!v || 'Email obrigatório']"
              @input="update('email', $event)"
              label="Email"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              id="v-card-number"
              v-mask="mask"
              data-card-field
              class="pt-0 mt-0"
              color="primary"
              required
              :value="card.cardNumber"
              :rules="[v => !!v || 'Número do cartão obrigatório']"
              @input="update('cardNumber', $event)"
              label="Número do cartão"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              id="v-card-name"
              data-card-field
              class="pt-0 mt-0"
              color="primary"
              required
              :value="card.cardName"
              :rules="[v => !!v || 'Titular obrigatório']"
              @input="update('cardName', $event)"
              label="Titular"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-select
              id="v-card-month"
              data-card-field
              class="pt-0 mt-0"
              color="primary"
              label="Mes"
              required
              :rules="[v => !!v || 'Mes obrigatório']"
              :value="card.cardMonth"
              @input="update('cardMonth', $event)"
              :items="months"
            ></v-select>
          </v-col>
          <v-col cols="6">
            <v-select
              id="v-card-year"
              data-card-field
              class="pt-0 mt-0"
              color="primary"
              :rules="[v => !!v || 'Ano obrigatório']"
              label="Ano"
              required
              :value="card.cardYear"
              @input="update('cardYear', $event)"
              :items="years"
            ></v-select>
          </v-col>
          <v-col cols="6">
            <v-text-field
              id="v-card-cvv"
              data-card-field
              class="pt-0 mt-0"
              color="primary"
              v-mask="'#####'"
              :rules="[v => !!v || 'CVV obrigatório']"
              required
              :value="card.cardCvv"
              @input="update('cardCvv', $event)"
              label="CVV"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-btn
          v-if="this.action"
          color="primary"
          dark
          submit
          @click="submit()"
          :loading="loading"
          block
        >
          {{ this.action === "create" ? "Adicionar Cartão" : "Editar" }}
        </v-btn>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { mask } from "vue-the-mask";
import { cloneDeep, tap, set } from "lodash";

export default {
  name: "card-form",
  directives: { mask },
  props: ["value", "loading", "action", "theme", "brand", "disableEmail", "errors"],
  data() {
    return {
      masks: {
        short: "#### #### #### ####", // Máscara para cartões de 16 dígitos
        long: "#### #### #### #### ###", // Máscara para cartões de 19 dígitos
      },
      labels: {
        cardName: "XXXXXXXXXX",
        cardHolder: "Titular",
        cardMonth: "XX",
        cardYear: "XXXX",
        cardCvv: "CVV",
        cardExpires: "Expiração",
      },
      months: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ],
      years: [],
    };
  },
  computed: {
    card() {
      return this.value;
    },
    mask() {
      return this.card.cardNumber.length <= 16
        ? this.masks.short
        : this.masks.long;
    },
  },
  async mounted() {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();

    for (let i = 0; i < 20; i++) {
      this.years.push((currentYear + i) + "");
    }
  },
  methods: {
    update(key, value) {
      this.$emit(
        "input",
        tap(cloneDeep(this.card), (v) => set(v, key, value))
      );
    },
    submit() {
      if (!this.$refs.form.validate()) {
        return false;
      }
      
      this.$emit("submit");
    },
  },
};
</script>