import { index, find, create, destroy, setCurrentCard } from "@/api/cards";

export default {
    state: {
        cards: [],
        loading: false,
        errors: {
            message: "",
            errors: ""
        }
    },
    mutations: {
        setCards(state, { cards }) {
            state.cards = cards;
        },

        setCardsLoading(state, { loading }) {
            state.loading = loading;
        },

        setCardsErrors (state, { errors }) {
            state.errors = errors;
        }
    },
    actions: {
        // commit, rootState
        async getCards({ commit }, { search = '' }) {
            try {
                commit('setCardsLoading', { loading: true });

                let { data } = await index({ search });

                commit('setCards', { cards: data.data });
            } catch (error) {
                let response = error.response.data;
                commit("setCardsErrors", { errors: response });
            } finally {
                commit('setCardsLoading', { loading: false });
            }
        },
        async storeCard({ commit }, { form }) {
            try {
                commit('setCardsLoading', { loading: true });

                let { data } = await create({ form });

                return data;
            } catch (error) {
                let response = error.response.data;
                commit("setCardsErrors", { errors: response });
                throw error;
            } finally {
                commit('setCardsLoading', { loading: false });
            }
        },

        async setCurrentCard({ commit }, { cardId }) {
            try {
                let { data } = await setCurrentCard({ cardId });
            } catch(error) {
                console.log(error);
                let response = error.response.data;
                commit("setCurrentCardsErrors", { errors: response });
            }
        },

        async destroyCard({ commit }, { cardId }) {
            try {
                let { data } = await destroy({ cardId });

                return data;
            } catch (error) {
                let response = error.response.data;
                commit("setCardsErrors", { errors: response });
            }
        },

        async findCard({ commit }, { cardId }) {
            try {
                commit('setCardsLoading', { loading: true });

                let { data } = await find({ cardId });

                return data;
            } catch (error) {
                let response = error.response.data;
                commit("setCardsErrors", { errors: response });
                throw error;
            } finally {
                commit('setCardsLoading', { loading: false });
            }
        },

        // async updatecardId({ commit }, { cardId, form }) {
        //     try {
        //         commit('setCardsLoading', { loading: true });

        //         let { data } = await update({ cardId, form });

        //         return data;
        //     } catch (error) {
        //         let response = error.response.data;
        //         commit("setCardsErrors", { errors: response });
        //         throw error;
        //     } finally {
        //         commit('setCardsLoading', { loading: false });
        //     }
        // },
    }
}