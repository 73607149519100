<template>
  <swiper
    :slides-per-view="1"
    :space-between="100"
    :centeredSlides="true"
    :slideToClickedSlide="true"
    class="mySwiper"
    :breakpoints="{
      '@0.00': {
        slidesPerView: 2,
        spaceBetween: 10,
      },
      '@0.75': {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      '@1.00': {
        slidesPerView: 3,
        spaceBetween: 40,
      },
    }"
  >
    <swiper-slide v-for="i in 5" :key="i">
      <v-skeleton-loader
        type="image"
        width="300"
        height="200"
        style="border-radius: 23px;"
      ></v-skeleton-loader>
    </swiper-slide>
  </swiper>
</template>

<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper-vue2";
import SwiperCore, { Pagination } from "swiper";

// Import Swiper styles
import "swiper/swiper.scss";

import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";

import "./style.css";

SwiperCore.use([Pagination]);

// install Swiper modules

export default {
  props: ["products"],
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {};
  },
  methods: {
    goToProduct(productId) {
      this.$router.push(`produto/${productId}`);
    }
  },
};
</script>