<template>
    <div style="height: 60px; overflow-y: none; overflow-x: hidden;  white-space: nowrap;" class="d-flex">
        <v-skeleton-loader
            v-for="i in 4"
            class="ma-2"
            type="chip"
            :key="i"
        ></v-skeleton-loader>
    </div>
</template>

<script>
export default {
    name: "chips-skeletons"
}
</script>