<template>
  <div class="py-3 pb-0">
    <div class="d-flex align-center justify-space-between">
      <div class="d-flex flex-column mb-5 pl-2" @click="toggleCard">
        <div class="d-flex flex-column">
          <div>#{{ card.id }}</div>
          <div class="d-flex align-center">
            <div style="width: 60px">
              <v-img :src="card.thumbnail" />
            </div>
            <div class="ml-5 d-flex flex-column">
              <span> **** {{ card.last_4 }} </span>
              <span class="caption gray--text">
                Vencimento: {{ card.expiration_date }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div>
        <v-btn
          v-if="otherCards ?? false"
          outlined
          x-small
          color="primary"
          @click="$emit('showSheet', true)"
        >
          alterar
          <v-icon style="font-size: 16px">mdi-swap-vertical</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "current-card",
  props: ["card", "current", "theme", "otherCards"],
  data() {
    return {
      items: [{ title: "Excluir", action: this.destroy }],
    };
  },
  methods: {
    toggleCard() {
      let elem = this.$refs.radioBtn.$el;
      elem.click();
    },
    edit() {
      this.$router.push("/card/" + this.card.id + "/editar");
    },
    destroy() {
      this.$emit("destroy", this.card.id);
    },
    setCard(card) {
      this.$emit("settedCard", card);
    },
  },
};
</script>
  
  <style scoped>
.current-card {
  color: #fff;
}
.current-card .v-card__subtitle {
  color: #fff;
}
.current-card button {
  color: #fff;
}
</style>