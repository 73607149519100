<script>
import Auth from '@/components/layouts/Auth';
import CustomerForm from '@/components/forms/Customer'
import PasswordForm from '@/components/forms/Password'
import { user } from '@/utils/auth';
import { mapActions, mapState } from 'vuex';

export default {
    name: "edit-customer",
    components: {
        Auth,
        CustomerForm,
        PasswordForm,
    },
    data() {
        return {
            customer: {
                name: "",
                email: "",
                phone: "",
            },
            password: {
                old_password: "",
                password: "",
                password_confirmation: "",
            }
        }
    },
    computed: {
        ...mapState({
            theme: (state) => state.theme.theme,
            loading: state => state.customers.loading,
            errors: state => state.customers.errors
        })
    },
    async mounted() {
        this.setCustomer(user());
    },
    methods: {
        async submit() {
            try {
                await this.updateProfile({ customer: this.customer });
                this.$toast.success("Perfil editado com sucesso!");
            } catch (error)  {
                this.$toast.error("Erro ao tentar editar  perfil!");
            }
        },
        async submitNewPassword() {
            try {
                await this.updatePassword({ password: this.password });
                this.$toast.success("Senha alterada com sucesso!");
            } catch (error) {
                this.$toast.error("Ocorreu um erro ao tentar alterar a senha!");
            }
        },
        setCustomer(data) {
            this.customer = {
                name: data.name,
                email: data.email,
                birth_date: data.birth_date,
                phone: data.phone,
            }
        },
        ...mapActions(['updateProfile', 'updatePassword'])
    }
}
</script>

<template>
    <v-container>
        <auth>
            <v-layout style="max-width: 700px" align-center justify-center="">
                <v-flex class="">
                    <customer-form
                        v-model="customer"
                        :loading="loading"
                        :errors="errors?.errors"
                        action="edit"
                        @submit="submit"></customer-form>
                    <v-spacer class="mt-5"></v-spacer>
                    <password-form
                        v-if="theme?.form?.show_password_field"
                        v-model="password"
                        :loading="loading"
                        :errors="errors?.errors"
                        action="edit"
                        @submit="submitNewPassword"></password-form>
                </v-flex>
            </v-layout>
        </auth>
        </v-container>
</template>