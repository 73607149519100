<template>
    <v-form>
        <h4>{{ action == 'edit' ? 'Dados do perfil' : (title ?? 'Crie sua conta') }}</h4>
        <v-card class="px-3 py-3 my-3">
            <v-text-field
                v-if="!theme?.form?.show_email_field"
                color="primary"
                :value="customer.phone"
                :disabled="loading"
                :error="!!errors?.phone || (!!errors['customer.phone'] ?? null)"
                :error-messages="errors?.phone || (errors['customer.phone'] ?? null)"
                @input="update('phone', $event)"
                placeholder="(xx) xxxxx-xxxx"
                label="Telefone"
                v-mask="phoneMask"
            ></v-text-field>
            <v-text-field 
                color="primary"
                :value="customer.name"
                :disabled="loading"
                :error="!!errors?.name || (!!errors['customer.name'] ?? null)"
                :error-messages="errors?.name || (errors['customer.name'] ?? null)"
                @input="update('name', $event)"
                label="Nome"
            ></v-text-field>
            <div v-show="action == 'edit' || (action == 'create' && theme?.form?.show_birth_date)">
                <v-menu
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            :value="formatDate(customer.birth_date)"
                            :disabled="loading || (action == 'edit' && customer?.birth_date)"
                            :error="!!errors?.birth_date || (errors['customer.birth_date'] ?? null)"
                            :error-messages="(errors?.birth_date && errors?.birth_date[0]) || (errors['customer.birth_date'] ?? null)"
                            @blur="update('birth_date', parseDate($event.srcElement._value))"
                            v-mask="dateMask"
                            label="Data de nascimento"
                            persistent-hint
                            append-icon="mdi-calendar"
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker
                        no-title
                        locale="pt"
                        :value="customer.birth_date"
                        :disabled="loading"
                        :error="!!errors?.birth_date || (errors['customer.birth_date'] ?? null)"
                        :error-messages="(errors?.birth_date && errors?.birth_date[0]) || (errors['customer.birth_date'] ?? null)"
                        @input="update('birth_date', $event)"
                    ></v-date-picker>
                </v-menu>
            </div>
            <v-text-field 
                color="primary"
                v-show="action == 'edit' || (action == 'create' && theme?.form?.show_email_field)"
                :value="customer.email"
                :disabled="loading || (action == 'edit' && customer?.email)"
                :error="!!errors?.email || (errors['customer.email'] ?? null)"
                :error-messages="(errors?.email && errors?.email[0]) || (errors['customer.email'] ?? null)"
                @input="update('email', $event)"
                label="Email"
            ></v-text-field>
            <v-text-field
                v-if="theme?.form?.show_email_field"
                color="primary"
                :value="customer.phone"
                :disabled="loading"
                :error="!!errors?.phone || (!!errors['customer.phone'] ?? null)"
                :error-messages="errors?.phone || (errors['customer.phone'] ?? null)"
                @input="update('phone', $event)"
                placeholder="(xx) xxxxx-xxxx"
                label="Telefone"
                v-mask="phoneMask"
            ></v-text-field>
            <div v-if="action == 'create' && theme?.form?.show_password_field">
                <v-text-field 
                    color="primary"
                    :value="customer.password"
                    :error="!!errors?.password || (errors['customer.password'] ?? null)"
                    :disabled="loading"
                    :error-messages="(errors?.password && errors?.password[0]) || (errors['customer.password'] ?? null)"
                    :append-icon="newPasswordShow ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="newPasswordShow ? 'text' : 'password'"
                    @click:append="newPasswordShow = !newPasswordShow"
                    @input="update('password', $event)"
                    :label="`${action == 'create' ? 'Senha' : 'Nova senha'}`"
                ></v-text-field>
                <v-text-field
                    color="primary"
                    :value="customer.password_confirmation"
                    :disabled="loading"
                    :error="!!errors?.password_confirmation"
                    :error-messages="errors?.password_confirmation && errors?.password_confirmation[0]"
                    :append-icon="passwordConfirmationShow ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="passwordConfirmationShow ? 'text' : 'password'"
                    @click:append="passwordConfirmationShow = !passwordConfirmationShow"
                    @input="update('password_confirmation', $event)"
                    label="Confirmação da senha"
                ></v-text-field>
            </div>
        </v-card>
        <v-btn
            v-if="action == 'edit'"
            color="primary"
            dark
            @click="submit()"
            :loading="loading"
            block>
            {{ this.action === 'create' ? 'Adicionar' : 'Editar Perfil' }} 
        </v-btn>
    </v-form>
</template>

<script>
import { mask } from 'vue-the-mask';
import { cloneDeep, tap, set } from 'lodash'

export default {
    name: "customer-form",
    props: ['value', 'title', 'loading', 'action', 'theme', 'errors'],
    directives: {mask},
    data() {
        return {
            phoneMask: "(##) #####-####",
            dateMask: "##/##/####",
            menu1: false,
            oldPasswordShow: false,
            newPasswordShow: false,
            passwordConfirmationShow: false,
        }
    },
    computed: {
        customer() {
            return this.value;
        },
    },
    methods: {
        formatDate (date) {
            if (!date) return null

            const [year, month, day] = date.split('-')
            return `${day}/${month}/${year}`
        },
        parseDate (date) {
            if (!date) return null

            const [day, month, year] = date.split('/')
            return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
        },
        update(key, value) {
            this.$emit('input', tap(cloneDeep(this.customer), v => set(v, key, value)))
        },
        submit() { this.$emit('submit')}
    }
}
</script>