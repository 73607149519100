<template>
  <div class="progress-indicators" style="width: 95%;">
    <span class="indicator" :style="`left: calc(${goal.goal / biggestGoal() * 100}% - 10px);`" v-for="goal in goals" :key="goal.goal">
      <span class="trophy">{{ totalIndications >= goal.goal ? '🏆' : '🎯' }}</span>
      <span class="number">{{ goal.goal }}</span>
    </span>
    <v-progress-linear :indeterminate="indeterminate" rounded :dark="true" height="20" :value="currentProgress">
      <strong v-if="!indeterminate">{{ totalIndications }} {{ totalIndications > 1 || totalIndications === 0 ? 'indicações' : 'indicação' }}</strong>
    </v-progress-linear>
  </div>
</template>

<script>
export default {
  name: "indications-progress",
  props: ['goals', 'totalIndications', 'indeterminate'],
  computed: {
    currentProgress() {
      return (this.totalIndications / this.biggestGoal()) * 100;
    }
  },
  methods: {
    biggestGoal() {
      let goals = Object.values(this.goals);

      return Math.max(...this.pluck(goals, 'goal'));
    }
  }
};
</script>

<style scoped>
.progress-indicators {
  position: relative;
}

.indicator {
  position: absolute;
  top: 20px;
  left: calc(20% - 10px);
  font-size: 14px;
}

.indicator .trophy {
  position: absolute;
  font-size: 20px;
  left: -3px;
}

.indicator .number {
  position: absolute;
  top: -40px;
  left: 1px;
}
</style>