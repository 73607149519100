<template>
    <div class="text-center">
        <v-bottom-sheet
            :value="value"
            @input="update"
            inset
            :persistent="loading"
        >
        <v-sheet
            class="text-center"
            style="height: 100%;"
        >
                <v-btn
                    class="mt-6"
                    text
                    color="primary"
                    @click="$emit('close', true)"
                >
                    Cancelar
                </v-btn>
                <div class="py-3 d-flex flex-column px-3" style="height: 100%;">
                    <v-layout v-if="loading" class="text-center">
                        <v-flex>
                            <v-progress-circular
                                indeterminate
                                color="primary"
                            ></v-progress-circular>
                        </v-flex>
                    </v-layout>
                    <div v-else>
                        <v-row>
                            <v-col v-for="(address, i) in addresses" :key="i" cols="12" sm="12" md="6" xl="3">
                                <v-card class="pa-2 pb-0" @click="setAddress(address.id)" v-ripple="{ center: true }">
                                    <current-address-card :theme="theme" :address="address" class="text-left" />
                                </v-card>
                            </v-col>
                        </v-row>
                    </div>
                    <v-btn class="mt-3" color="primary" @click="$emit('newAddress', true)">
                        Adicionar novo
                    </v-btn>
                </div>
            </v-sheet>
        </v-bottom-sheet>
    </div>
</template>

<script>
import CurrentAddressCard from '@/components/cards/CurrentAddressCard'

export default {
    name: "AddressBottomSheet",
    props: ['value','addresses', 'loading', 'theme'],
    components: {
        CurrentAddressCard
    },
    methods: {
      update(newValue) { this.$emit('input', newValue); },
      setAddress(newAddressId) {
        let newAddress = this.addresses
            .filter((address) => address.id == newAddressId);

        this.$store.commit('setCurrentAddress', {
            address: newAddress
        });

        this.$emit('updateTax', newAddress[0]?.tax ?? null);

        this.$emit('close', true);
        this.$toast.success('Endereço alterado');
      }
    }
}
</script>