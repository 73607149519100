export default {
    state: {
        bag: [],
        loading: false,
        summary: null,
        errors: {
            message: "",
            errors: ""
        }
    },
    mutations: {
        initialiseStore(state) {
			if(localStorage.getItem('bag')) {
                state.bag = JSON.parse(localStorage.getItem('bag'));
			}
		},

        setSummary(state, summary) {
            state.summary = summary;
        },

        setBag(state, { bag }) {
            state.bag = bag;
            localStorage.setItem('bag', JSON.stringify(state.bag));
        },

        removeItemFromBag(state, { productId }) {
            let index = state.bag.findIndex((el) => el.id == productId);
            state.bag.splice(index, 1);

            localStorage.setItem('bag', JSON.stringify(state.bag));
        },
        changeProductObservationFromBag(state, { productId, observations }) {
            let product = state.bag.find((el) => el.id == productId);
            product.observations = observations;

            state.bag[state.bag.indexOf(product)] = product;

            localStorage.setItem('bag', JSON.stringify(state.bag));
        },
        addItemToBag(state, { item }) {
            let productIndex = state.bag.findIndex(function (product) {
                return product.id == item.id
                    && product.observations == item.observations
                    && JSON.stringify(product.compositions) == JSON.stringify(item.compositions)
            });

            if (productIndex > -1) {
                let product = state.bag[productIndex];
                product.quantity += item.quantity;
                state.bag[productIndex] = product;
            } else {
                state.bag.push(item);
            }

            localStorage.setItem('bag', JSON.stringify(state.bag));
        },

        setBagLoading(state, { loading }) {
            state.loading = loading;
        },

        setBagErrors (state, { errors }) {
            state.errors = errors;
        }
    },
    actions: {
        addItemToBag({ commit }, { item }) {
            try {
                commit('setBagLoading', { loading: true });
                commit('addItemToBag', { item });
            } catch (error) {
                let response = error.response.data;
                commit("setBagError", { errors: response });
            } finally {
                commit('setBagLoading', { loading: false });
            }
        },
    }
}