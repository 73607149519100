<template>
  <v-form>
    <h4>Dados do endereço de entrega</h4>
    <v-card class="px-3 py-3 my-3">
      <v-row>
        <v-col cols="12">
          <v-text-field
            class="pt-0 mt-0"
            color="primary"
            :value="address?.name"
            :disabled="loading"
            :error="!!errors?.name || (errors['address.name'] ?? null)"
            :error-messages="
              (errors?.name && errors?.name[0]) ||
              (errors['address.name'] ?? null)
            "
            @input="update('name', $event)"
            hint="Ex:. Casa, trabalho, casa da vóvo..."
            label="Nome"
          ></v-text-field>
        </v-col>
        <v-col cols="6" v-if="theme?.form?.show_cep_field">
          <v-text-field
            class="pt-0 mt-0"
            color="primary"
            :value="address.zipcode"
            :disabled="loading"
            :error="!!errors?.zipcode || (errors['address.zipcode'] ?? null)"
            :error-messages="
              (errors?.zipcode && errors?.zipcode[0]) ||
              (errors['address.zipcode'] ?? null)
            "
            @input="update('zipcode', $event)"
            label="CEP"
            v-mask="maskZipcode"
          ></v-text-field>
        </v-col>
        <v-col cols="6" v-if="theme?.form?.show_state_field">
          <v-text-field
            class="pt-0 mt-0"
            color="primary"
            :value="address.state"
            :disabled="loading"
            :error="!!errors?.state || (errors['address.state'] ?? null)"
            :error-messages="
              (errors?.state && errors?.state[0]) ||
              (errors['address.state'] ?? null)
            "
            @input="update('state', $event)"
            label="Estado"
          ></v-text-field>
        </v-col>
        <v-col cols="8">
          <v-text-field
            class="pt-0 mt-0"
            color="primary"
            :value="address.street"
            :disabled="loading"
            :error="!!errors?.street || (errors['address.street'] ?? null)"
            :error-messages="
              (errors?.street && errors?.street[0]) ||
              (errors['address.street'] ?? null)
            "
            @input="update('street', $event)"
            label="Rua"
          ></v-text-field>
        </v-col>
        <v-col cols="4">
          <v-text-field
            class="pt-0 mt-0"
            color="primary"
            :value="address.number"
            :disabled="loading"
            :error="!!errors?.number || (errors['address.number'] ?? null)"
            :error-messages="
              (errors?.number && errors?.number[0]) ||
              (errors['address.number'] ?? null)
            "
            @input="update('number', $event)"
            label="Número"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            class="pt-0 mt-0"
            color="primary"
            :value="address.complement"
            :disabled="loading"
            :error="
              !!errors?.complement || (errors['address.complement'] ?? null)
            "
            :error-messages="
              (errors?.complement && errors?.complement[0]) ||
              (errors['address.zipcode'] ?? null)
            "
            @input="update('complement', $event)"
            label="Complemento"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            class="pt-0 mt-0"
            color="primary"
            :value="address.city"
            :disabled="loading"
            :error="!!errors?.city || (errors['address.city'] ?? null)"
            :error-messages="
              (errors?.city && errors?.city[0]) ||
              (errors['address.city'] ?? null)
            "
            @input="update('city', $event)"
            label="Cidade"
          ></v-text-field>
        </v-col>
        <v-col :class="`${theme?.district_tax ? 'pt-2' : ''}`">
          <v-text-field
            v-if="!theme?.district_tax"
            class="pt-0 mt-0"
            color="primary"
            :value="address.district"
            :disabled="loading"
            :error="!!errors?.district || (errors['address.district'] ?? null)"
            :error-messages="
              (errors?.district && errors?.district[0]) ||
              (errors['address.district'] ?? null)
            "
            @input="update('district', $event)"
            label="Bairro"
          ></v-text-field>
          <v-autocomplete
            v-else
            :items="districts"
            :value="address.district"
            @input="districtListUpdate($event)"
            :loading="districtLoading"
            style="padding-top: 0px"
            density="comfortable"
            item-text="title"
            item-value="name"
            hint="Taxa de entrega pode variar de acordo com o bairro"
            label="Bairro"
          ></v-autocomplete>
        </v-col>
      </v-row>
    </v-card>
    <v-btn
      v-if="this.action"
      color="primary"
      dark
      @click="submit()"
      :loading="loading"
      block
    >
      {{ this.action === "create" ? "Adicionar" : "Editar" }}
    </v-btn>
  </v-form>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { mask } from "vue-the-mask";
import { cloneDeep, tap, set } from "lodash";

export default {
  name: "address-form",
  props: ["value", "loading", "action", "theme", "errors"],
  directives: { mask },
  async mounted() {
    if (this?.theme?.district_tax) {
      await this.getDistricts({ search: "" });
    }
  },
  data() {
    return {
      maskZipcode: "#####-###",
    };
  },
  computed: {
    address() {
      return this.value;
    },
    ...mapState({
      districts: (state) => state.districts.districts,
      districtLoading: (state) => state.districts.loading,
      districtErrors: (state) => state.districts.errors,
    }),
  },
  methods: {
    districtListUpdate($event) {
        let district = this.districts.find(district => district.name === $event) ?? null;

        this.$emit('updateTax', district?.tax);
        this.update('district', $event)
    },
    update(key, value) {
      this.$emit(
        "input",
        tap(cloneDeep(this.address), (v) => set(v, key, value))
      );
    },
    submit() {
      this.$emit("submit");
    },
    ...mapActions(["getDistricts"]),
  },
};
</script>

<style>
</style>