<template>
  <v-card
      outlined
      style="border-radius: 15px; border: none; box-shadow: 0px 2px 24px -5px rgba(0, 0, 0, 0.25);"
      @click="!isAvailableOnStock(product) ? null : goToProduct(product.id)"
>
<div class="d-flex" style="overflow: hidden; position: relative; padding: 1px;">
  <v-img
      style="border-radius: 15px; min-width: 130px;"
      class="mr-3"
      lazy-src="@/assets/product-image-placeholder.jpg"
      max-width="130"
      :src="product.imagem"
  ></v-img>
    <div v-if="!isAvailableOnStock(product)" class="ribbon ribbon-top-left ribbon-secondary"><span>Indisponível</span></div>
    <div v-else-if="product.free_rate_quantity" class="ribbon ribbon-top-left ribbon-red"><span>Frete grátis</span></div>
  <v-chip
    v-if="product.has_promotions"
    class="promotion ma-2"
    color="green"
    x-small
  >
    PROMOÇÃO
  </v-chip>
  <div class="d-flex flex-column justify-space-between" style="width: 100%;">
      <span class="font-weight-medium" style="font-size: 1rem;">{{ product.name }}</span>
      <div style="height: 45px; overflow-y: auto; font-size: 10px;">
          <span>{{ product.tags ? product.tags.join(', ') : '' }}</span>
      </div>

      <div class="d-flex align-center justify-space-between">
          <span class="title primary--text">{{ formatMoney(product.price || product?.compositions[0]?.options[0]?.additional_price || 0) }}</span>
      </div>
  </div>
</div>
</v-card>
</template>

<script>
export default {
  name: "ProductListCard",
  props: ['product'],
  methods: {
      goToProduct(productId) {
          this.$router.push(`produto/${productId}`);
      }
  }
}
</script>

<style>
.ribbon {
    width: 150px;
    height: 150px;
    overflow: hidden;
    position: absolute;
}
.ribbon::before,
.ribbon::after {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    border: 5px solid #2980b9;
}
.ribbon span {
    position: absolute;
    display: block;
    width: 225px;
    padding: 15px 0;
    box-shadow: 0 5px 10px rgba(0,0,0,.1);
    color: #fff;
    font: 700 18px/1 'Lato', sans-serif;
    font-size: 10px;
    text-shadow: 0 1px 1px rgba(0,0,0,.2);
    text-transform: uppercase;
    text-align: center;
}

.ribbon-red span {
  background-color: #DD1C1E;
}

.ribbon-secondary span {
  background-color: #A6A6A6;
}

/* top left*/
.ribbon-top-left {
    top: -15px;
    left: -20px;
}
.ribbon-top-left::before,
.ribbon-top-left::after {
    border-top-color: transparent;
    border-left-color: transparent;
}
.ribbon-top-left::before {
    top: 0;
    right: 0;
}
.ribbon-top-left::after {
    bottom: 0;
    left: 0;
}
.ribbon-top-left span {
    right: -25px;
    top: 30px;
    height: 4px;
    padding-bottom: 18px;
    padding-top: 7px;
    padding-right: 20px;
    transform: rotate(-45deg);
}

.promotion {
    color: #fff !important;
    position: absolute;
    bottom: 0;
    left: calc(5% - 2px);
}
</style>