<template>
  <div class="d-md-flex justify-center">
    <v-layout style="max-width: 1300px" column class="mt-5">
      <page-title title="detalhes do pedido"></page-title>
      <div class="mt-10">
        <div class="container">
          <v-layout v-if="loading" class="text-center">
            <v-flex>
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-flex>
          </v-layout>
          <v-layout v-else class="d-flex flex-column">
            <div class="status">
              <v-icon style="font-size: 16px">{{ status.icon }}</v-icon>
              Pedido {{ status.text }} - {{ order.hash }}
            </div>
            <div class="mt-5">
              <span class="font-weight-bold">Data do pedido:</span> {{ formatDate(order.created_at) }} {{ formatDateToTime(order.created_at) }}
            </div>
            <div class="mt-5">
              <div class="mb-6">
                <v-row>
                  <v-col
                    v-for="(product, i) in order.products"
                    :key="i"
                    cols="12"
                    sm="12"
                    md="6"
                    xl="3"
                  >
                    <order-detail-product-card
                      :product="product"
                    ></order-detail-product-card>
                  </v-col>
                </v-row>
              </div>
              <span class="title gray--text">Resumo de valores</span>
              <div class="subtitle-2">
                <div class="d-flex justify-space-between gray--text">
                  <span>Subtotal</span>
                  <span>{{ formatMoney(order.amount - order.tax) }}</span>
                </div>
                <div class="d-flex justify-space-between gray--text" v-if="!order?.dining_table_id">
                  <!-- TODO: Adicionar taxa de entrega no pedido -->
                  <span>Taxa de entrega</span>
                  <span>{{
                    order.tax > 0 ? formatMoney(order.tax) : "Grátis"
                  }}</span>
                </div>
                <div
                  v-if="order?.debit_transaction?.amount"
                  class="d-flex justify-space-between success--text"
                >
                  <span>Crédito utilizado</span>
                  <span>- {{ formatMoney(order?.debit_transaction?.amount) }}</span>
                </div>
                <div
                  v-if="order.coupon_id"
                  class="d-flex justify-space-between success--text"
                >
                  <span>Cupom</span>
                  <span>- {{ formatMoney(order.discount_amount) }}</span>
                </div>
                <div class="d-flex justify-space-between text-font-weight mt-4">
                  <span>Total</span>
                  <span>{{ formatMoney(order.paid_amount) }}</span>
                </div>
              </div>
            </div>
            <div class="d-flex flex-column mt-10" v-if="order?.address">
              <span style="font-size: 16px" class="gray--text mb-2"
                >Endereço de entrega</span
              >
              <div>
                <span>{{ order.address?.street }}</span
                >,
                <span>{{ order.address?.number }}</span>
                <!-- TODO: Adicionar referência -->
                <!-- <span v-if="">{{ order.address?.number }}</span> -->
              </div>
              <div>
                <span>{{ order.address?.district }}</span> -
                <span>{{ order.address?.city }}</span> -
                <span>{{ order.address?.state }}</span>
              </div>
              <div class="mt-1">
                <span class="ml-3 caption gray--text"
                  >- {{ order.address?.name }}</span
                >
              </div>
            </div>
            <div class="d-flex flex-column mt-10" v-if="order?.credit_transaction?.amount">
              <span style="font-size: 16px" class="gray--text mb-2"
                >Bonificação</span
              >
              <ul>
                <li v-if="order?.credit_transaction?.status == 'approved'">Cashback: <span class="font-weight-bold">Esse pedido gerou {{ formatMoney(order.credit_transaction.amount) }} de cashback</span></li>
                <li v-else>Cashback: <span class="font-weight-bold">Seu crédito será acrescido em {{ formatMoney(order.credit_transaction.amount) }} mediante conclusão do pedido</span></li>
              </ul>
            </div>
            <div class="mt-5" v-if="!order?.address && !order?.dining_table">
              <span style="font-size: 16px" class="gray--text">
                RETIRAR PRESENCIALMENTE
              </span>
            </div>
            <div class="mt-5" v-if="order?.dining_table">
              <span class="font-weight-bold">Origem do pedido</span><br />
              <span style="font-size: 16px" class="gray--text">
                {{ order?.dining_table?.name }}
              </span>
            </div>
            <v-btn
              v-if="theme.phone && !theme.hide_whatsapp_share"
              class="mt-15"
              color="green"
              dark
              @click="sendWhatsapp"
              rounded
              block
            >
              <v-icon class="mr-2"> mdi-whatsapp </v-icon>
              Salvar no Whatsapp
            </v-btn>
          </v-layout>
        </div>
      </div>
    </v-layout>
  </div>
</template>

<script>
import PageTitle from "@/components/layouts/PageTitle";
import OrderDetailProductCard from "@/components/cards/OrderDetailProductCard";
import { mapState, mapActions } from "vuex";

export default {
  name: "orders-show",
  components: {
    PageTitle,
    OrderDetailProductCard,
  },
  data() {
    return {
      orderId: this.$route.params.id,
      poolInterval: null,
    };
  },
  async created() {
    if (this.order.id != this.orderId) {
      await this.getOrder({ id: this.orderId });
    }

    if (this.order.status != 'finished') {
      this.poolInterval = setInterval(async () => {
        await this.orderStatus();
      }, 15000);
      setTimeout(() => { clearInterval(this.poolInterval) }, 36000000);
    }
  },
  async beforeDestroy() {
    clearInterval(this.poolInterval);
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      theme: (state) => state.theme.theme,
      orders: (state) => state.orders.orders,
      order: (state) => state.orders.order,
      loading: (state) => state.orders.loading,
    }),
    status() {
      return this.getOrderStatus(this.order.status, this.order?.address);
    },
  },
  methods: {
    ...mapActions(["getOrder", "fetchOrderStatus"]),
    async orderStatus() {
      let status = await this.fetchOrderStatus({ id: this.orderId });

      if (status == "finished") {
        clearInterval(this.poolInterval);
      }
    },
    sendWhatsapp() {
      if (!this.theme.phone) return false;

      let paymentMethod = {
        'credit_card': 'Cartão de crédito',
        'debit_card': 'Cartão de débito',
        'money': 'Dinheiro',
        'pix': 'PIX',
      };

      let message = `Confirmação de Pedido *${this.theme.siteName}* (Meodelivery)%0a%0a`;
      message += `*Pedido*: ${this.order.hash}%0a`;
      message += `*Data*: ${this.formatDate(this.order.created_at)}%0a`;
      message += `*Horário*: ${this.formatDateToTime(
        this.order.created_at
      )}%0a`;
      // message += `*Tipo de entrega*:  %0a`;
      // message += `*Tempo estimado*: %0a`;
      message += "----------------------------%0a";
      message += `*Nome*: ${this.user.name}%0a`;
      message += `*Fone*: ${this.user.phone}%0a`;
      if (this.order?.address) {
        message += `*Endereço*: ${this.order.address?.city}, ${this.order.address?.street}, ${this.order.address?.number}%0a`;
        message += `*Bairro*: ${this.order.address?.district}%0a`;
        message += `*Complemento*: %0a`;
      } else {
        message += `**RETIRAR PRESENCIALMENTE** %0a`;
      }

      for (let i in this.order.products) {
        let product = this.order.products[i];

        message += "----------------------------%0a";
        message += `*- ${product.quantity}x ${product.name}* %0a`;

        for (let compositionType in product.selectedComposition) {
          let composition = product.selectedComposition[compositionType];
          message += `-- ${compositionType} %0a`;

          if (composition instanceof Array) {
            for (let j in composition) {
              let option = composition[j];

              message += `--- ${option.name} `;

              if (option.additional_price) {
                message += `${this.formatMoney(option.additional_price)}`;
              }

              message += "%0a";
            }
          } else {
            message += `--- ${composition.name} `;

            if (composition.additional_price) {
              message += `${this.formatMoney(composition.additional_price)}`;
            }

            message += "%0a";
          }
        }

        message += `*Obs:* ${product.observation ?? ""}%0a`;
        message += "----------------------------%0a";
      }

      message += `*Itens*: ${this.formatMoney(
        this.order.amount - this.order.tax
      )}%0a`;
      message += `*Entrega*: ${this.formatMoney(this.order.tax)}%0a`;
      message += `*Desconto*: ${this.formatMoney(
        this.order.discount_amount
      )}`;

      if (this.order?.debit_transaction?.amount) {
        message += `%0a*Créditos utilizados*: ${this.formatMoney(
          this.order?.debit_transaction?.amount
        )}`;
      }

      message += `%0a%0a*TOTAL*: ${this.formatMoney(this.order.paid_amount)}%0a`;

      paymentMethod = paymentMethod[this.order.payment_method];

      message += `*Forma de pagamento*: ${paymentMethod}\n`;

      if (this?.order?.change_value ?? null) {
          message += `*Valor de troco*: ${this.order.change_value}\n`;
      }

      window.open(`https://wa.me/55${this.theme.phone}?text=${message}`);
    },
  },
};
</script>

<style>
.status {
  width: 100%;
  text-align: center;
  font-size: 12px;
  padding: 13px 0px;
  border-radius: 50px;
  border: 1px solid #2adfb4;
}
</style>