import { index, create, show, status } from "@/api/orders";

export default {
    state: {
        orders: {
            meta: {
                current_page: 0,
            },
            data: []
        },
        order: "",
        loading: false,
        errors: {
            message: "",
            errors: ""
        }
    },
    mutations: {
        setOrders(state, { orders }) {
            if (state.orders.data.length > 0) {
                let newOrders = state.orders.data.concat(orders.data);
                state.orders = orders;
                state.orders.data = newOrders;
            } else {
                state.orders = orders;
            }
        },

        setOrder(state, { order }) {
            state.order = order;
        },

        setOrderStatus(state, { status }) {
            state.order.status = status;
        },

        setOrdersLoading(state, { loading }) {
            state.loading = loading;
        },

        setOrdersErrors (state, { errors }) {
            state.errors = errors;
        }
    },
    actions: {
        // commit, rootState
        async getOrders({ commit }, { search = '', page = 1 }) {
            try {
                commit('setOrdersLoading', { loading: true });

                let { data } = await index({ search, page });
                
                commit('setOrders', { orders: data });
            } catch (error) {
                let response = error.response.data;
                commit("setOrdersError", { errors: response });
            } finally {
                commit('setOrdersLoading', { loading: false });
            }
        },

        async getOrder({ commit }, { id }) {
            try {
                commit('setOrdersLoading', { loading: true });

                let { data } = await show({ id });
                
                commit('setOrder', { order: data });
            } catch (error) {
                let response = error.response.data;
                commit("setOrdersError", { errors: response });
            } finally {
                commit('setOrdersLoading', { loading: false });
            }
        },

        async fetchOrderStatus({ commit }, { id }) {
            try {
                let { data } = await status({ id });
                
                commit('setOrderStatus', data);

                return data?.status;
            } catch (error) {
                let response = error.response.data;
                commit("setOrdersError", { errors: response });
            }
        },

        async storeOrder({ commit }, { form }) {
            try {
                commit('setOrdersLoading', { loading: true });
                
                let { data } = await create({ form });

                return data;
            } catch (error) {
                let response = error.response.data;
                commit("setOrdersErrors", { errors: response });
                throw error;
            } finally {
                commit('setOrdersLoading', { loading: false });
            }
        },
    }
}