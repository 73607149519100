import { index, find, highlights } from "@/api/products";

export default {
    state: {
        categories: [],
        products: {
            current_page: 0,
            data: []
        },
        highlights: [],
        product: "",
        highlightsLoading: false,
        loading: false,
        categoryFilterLoading: false,
        errors: {
            message: "",
            errors: ""
        }
    },
    mutations: {
        setCategories(state, { categories }) {
            state.categories = categories;
        },

        setProducts(state, { products }) {
            state.products = products;
        },

        appendProducts(state, { products }) {
            let newProducts = state.products.data.concat(products.data);
            state.products = products;
            state.products.data = newProducts;
        },

        setProductsTotalPages (state, { totalPages }) {
            state.productsTotalPages = totalPages;
        },

        setHighlights(state, { highlights }) {
            state.highlights = highlights;
        },

        setProduct(state, { product }) {
            state.product = product;
        },
        
        setCategoryFilterLoading(state, { loading }) {
            state.categoryFilterLoading = loading;
        },

        setProductsLoading(state, { loading }) {
            state.loading = loading;
        },

        setHighlightsLoading(state, { loading }) {
            state.highlightsLoading = loading;
        },

        setProductsErrors (state, { errors }) {
            state.errors = errors;
        }
    },
    actions: {
        // commit, rootState
        async getProducts({ commit }, { search = '', categoryId = '', page = 1 }) {
            try {
                commit('setProductsLoading', { loading: true });

                if (page == 1) {
                    commit('setProducts', { products: {
                        current_page: 0,
                        data: []
                    }});
                }

                let { data } = await index({ search, categoryId, page });

                if (page == 1) {
                    commit('setProducts', { products: data });
                } else {
                    commit('appendProducts', { products: data });
                }
            } catch (error) {
                let response = error.response.data;
                commit("setProductsError", { errors: response });
            } finally {
                commit('setProductsLoading', { loading: false });
            }
        },

        async getHighlights({ commit }, { search = '', categoryId = '' }) {
            try {
                commit('setHighlightsLoading', { loading: true });

                let { data } = await highlights({ search, categoryId });

                commit('setHighlights', { highlights: data });
            } catch (error) {
                let response = error.response.data;
                commit("setProductsError", { errors: response });
            } finally {
                commit('setHighlightsLoading', { loading: false });
            }
        },

        async findProduct({ state, commit }, { productId }) {
            try {
                commit('setProductsLoading', { loading: true });

                let { data } = await find({ productId });

                commit('setProduct', { product: data });

                return data;
            } catch (error) {
                let response = error.response.data;
                commit("setProductsError", { errors: response });
            } finally {
                commit('setProductsLoading', { loading: false });
            }
        },
    }
}