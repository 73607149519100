<script>
import Auth from '@/components/layouts/Auth';
import AddressForm from '@/components/forms/Address'
import { mapActions, mapState } from 'vuex';

export default {
    name: "edit-address",
    components: {
        Auth,
        AddressForm
    },
    data() {
        return {
            addressId: this.$route.params.id,
            address: {
                name: "",
                zipcode: "",
                state: "",
                city: "",
                complement: "",
                district: "",
                number: "",
            },
        }
    },
    computed: {
        ...mapState({
            theme: (state) => state.theme.theme,
            loading: state => state.addresses.loading,
            errors: state => state.addresses.errors
        })
    },
    async mounted() {
        let address = await this.findAddress({ addressId: this.addressId });
        this.setAddress(address)
    },
    methods: {
        async submit() {
            let data = await this.updateAddress({ addressId: this.addressId, form: this.address });
            
            if (data) {
                this.$router.replace({ name: 'addresses-index' });
                await this.getAddresses({ search: null });
            }
        },
        setAddress(data) {
            this.address = {
                name: data.name,
                zipcode: data.zipcode,
                state: data.state,
                city: data.city,
                street: data.street,
                complement: data.complement,
                district: data.district,
                number: data.number,
            }
        },
        ...mapActions(["storeAddress", "findAddress", "updateAddress", "getAddresses"])
    }
}
</script>

<template>
    <v-container>
        <v-layout align-center justify-center="" class="mt-15">
            <v-flex>
                <address-form
                    v-model="address"
                    :theme="theme"
                    :loading="loading"
                    :errors="errors"
                    action="edit"
                    @submit="submit"></address-form>
            </v-flex>
        </v-layout>
    </v-container>
</template>