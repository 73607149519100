<template>
    <v-form>
        <h4>Alterar senha</h4>
        <v-card class="px-3 py-3 my-3">
            <v-text-field 
                color="primary"
                :value="password.old_password"
                :disabled="loading"
                :error="!!errors?.old_password"
                :error-messages="errors?.old_password && errors?.old_password[0]"
                :append-icon="oldPasswordShow ? 'mdi-eye' : 'mdi-eye-off'"
                :type="oldPasswordShow ? 'text' : 'password'"
                @click:append="oldPasswordShow = !oldPasswordShow"
                @input="update('old_password', $event)"
                label="Senha antiga"
            ></v-text-field>
            <v-text-field 
                color="primary"
                :value="password.password"
                :error="!!errors?.password"
                :disabled="loading"
                :error-messages="errors?.password && errors?.password[0]"
                :append-icon="newPasswordShow ? 'mdi-eye' : 'mdi-eye-off'"
                :type="newPasswordShow ? 'text' : 'password'"
                @click:append="newPasswordShow = !newPasswordShow"
                @input="update('password', $event)"
                label="Nova senha"
            ></v-text-field>
            <v-text-field
                color="primary"
                :value="password.password_confirmation"
                :disabled="loading"
                :error="!!errors?.password_confirmation"
                :error-messages="errors?.password_confirmation && errors?.password_confirmation[0]"
                :append-icon="passwordConfirmationShow ? 'mdi-eye' : 'mdi-eye-off'"
                :type="passwordConfirmationShow ? 'text' : 'password'"
                @click:append="passwordConfirmationShow = !passwordConfirmationShow"
                @input="update('password_confirmation', $event)"
                label="Confirmação da senha"
            ></v-text-field>
        </v-card>
        <v-btn
            color="primary"
            dark
            @click="submit()"
            :loading="loading"
            block>
            {{ this.action === 'create' ? 'Adicionar' : 'Atualizar senha' }} 
        </v-btn>
    </v-form>
</template>

<script>
import { mask } from 'vue-the-mask';
import { cloneDeep, tap, set } from 'lodash'

export default {
    name: "password-form",
    props: ['value', 'loading', 'action', 'errors'],
    directives: {mask},
    data() {
        return {
            oldPasswordShow: false,
            newPasswordShow: false,
            passwordConfirmationShow: false,
        }
    },
    computed: {
        password() {
            return this.value;
        },
    },
    methods: {
      update(key, value) {
        this.$emit('input', tap(cloneDeep(this.password), v => set(v, key, value)))
      },
      submit() { this.$emit('submit')}
    }
}
</script>