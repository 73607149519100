import router from "@/router";
import { getAuthenticatedUser as getUser, login } from '@/api/auth';
import { user, token } from '@/utils/auth';

export default {
    state: {
        user: null,
        loading: false,
        errors: {
            message: "",
            errors: ""
        }
    },
    mutations: {
        initialiseUser(state) {
			if(localStorage.getItem('user')) {
                state.user = user();
			}
		},

        setAuthenticatedUser(state, { user }) {
            state.user = user;
        },

        setCurrentAddress(state, { address }) {
            state.user.current_address = address;

            let newCustomer = {
                token: token(),
                customer: { ...state.user }
            }

            localStorage.setItem('user', JSON.stringify(newCustomer));
        },

        setCurrentCard(state, { card }) {
            state.user.current_card = card;

            let newCustomer = {
                token: token(),
                customer: { ...state.user }
            }

            localStorage.setItem('user', JSON.stringify(newCustomer));
        },

        setAuthLoading(state, { loading }) {
            state.loading = loading;
        },
        
        setAuthErrors(state, { errors }) {
            state.errors = errors;
        }
    },
    actions: {
        // commit, rootState
        async login({ commit, dispatch }, { email, phone, password, withoutRedirect = false }) {
            try {
                commit("setAuthLoading", { loading: true });

                let { data } = await login({ email, phone, password });

                localStorage.setItem('user', JSON.stringify(data));

                commit("setAuthenticatedUser", { user: user() });

                window.axios.defaults.headers.common["Authorization"] = `Bearer ${token()}`;

                if (!withoutRedirect) {
                    router.push('/');
                }

                return true;
            } catch(error) {
                let response = error.response.data;
                commit("setAuthErrors", { errors: response });
            } finally {
                commit("setAuthLoading", { loading: false });
                return false;
            }
        },

        async logout({ commit, dispatch }) {
            localStorage.removeItem("user");
            commit("setAuthenticatedUser", { user: null });
            window.axios.defaults.headers.common["Authorization"] = null;
            router.push(
                "/"
            );
        },

        async getAuthenticatedUser({ commit }) {
            let { data } = await getUser();
            let userData = JSON.parse(localStorage.getItem("user"));
            userData.customer = data;

            localStorage.setItem('user', JSON.stringify(userData));
            commit("setAuthenticatedUser", { user: user() });
        },
    }
}