import client from '@/api/client';

export async function index({ search }) {
    return await client.get(`/cards`);
}

export async function find({ cardId }) {
    return await client.get(`/cards/${cardId}`);
}

export async function create({ form }) {
    return await client.post(`/cards`, form);
}

export async function destroy({ cardId }) {
    let form = {
        address_id: cardId,
        "_method": "DELETE"
    };

    return await client.post(`/cards/${cardId}`, form, { headers: { "Content-Type": "multipart/form-data" } });
}

export async function setCurrentCard({ cardId }) {
    let form = {
        card_id: cardId,
        "_method": "PUT"
    };

    return await client.post(`/cards/change-current-card`, form, { headers: { "Content-Type": "multipart/form-data" } });
}