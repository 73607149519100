<template>
  <div>
    <v-btn
      color="white"
      block
      small
      rounded
      :disabled="disabled"
      @click.stop="cashbackToUse ? cancel() : (dialog = true)"
    >
      {{ cashbackToUse ? "Cancelar uso de créditos" : "Usar créditos" }}
    </v-btn>

    <v-dialog v-model="dialog" max-width="290">
      <v-card>
        <v-card-title class="text-h5" style="font-size: 16px !important">
          Usar créditos para o pagamento?
        </v-card-title>

        <v-card-text>
          <div class="mb-3">
            <span class="font-weight-medium">Será usado: </span
            ><span>
              {{
                credits > total ? formatMoney(total) : formatMoney(credits)
              }}</span
            ><br />
          </div>
          <div class="d-flex justify-space-between">
            <div>
              <span class="font-weight-medium">Saldo atual: </span><br /><span>
                {{ formatMoney(credits) }}</span
              >
            </div>
            <div class="d-flex align-center">
              <div>
                <span> ----> </span>
              </div>
            </div>
            <div>
              <span class="font-weight-medium">Saldo restante: </span
              ><br /><span>
                {{ credits > total ? formatMoney(credits - total) : 0 }}</span
              >
            </div>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text @click="dialog = false"> Cancelar </v-btn>

          <v-btn color="green darken-1" text @click="use"> Usar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
  
<script>
export default {
  name: "cashback-dialog",
  props: ["credits", "total", "cashbackToUse", "disabled"],
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    use() {
      this.$emit("use", this.credits > this.total ? this.total : this.credits);
      this.dialog = false;
    },
    cancel() {
      this.$emit("cancel");
    },
  },
};
</script>