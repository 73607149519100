<template>
  <swiper
    :slides-per-view="1"
    :space-between="100"
    :centeredSlides="true"
    :slideToClickedSlide="true"
    class="mySwiper"
    :breakpoints="{
      '@0.00': {
        slidesPerView: 2,
        spaceBetween: 10,
      },
      '@0.75': {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      '@1.00': {
        slidesPerView: 5,
        spaceBetween: 40,
      },
    }"
  >
    <swiper-slide v-for="(product, i) in products" :key="i">
        <img :src="product.imagem" lazy-src="@/assets/product-image-placeholder.jpg" @error="$event.target.src=require(`@/assets/product-image-placeholder.jpg`)" >
        <div class="slide-overlay" @click="goToProduct(product.id)">
          <div class="ml-3 mb-2 d-flex flex-column align-start" style="width: calc(100% - 26px);">
            <div v-if="product.free_rate_quantity" class="ribbon ribbon-top-left"><span>Frete grátis</span></div>
            <v-chip
              v-if="product.has_promotions"
              class="promotion ma-2"
              color="green"
              x-small
            >
              PROMOÇÃO
            </v-chip>
            <span class="text-left">
              {{ product.name }}
            </span>
            <div class="d-flex justify-space-between" style="width: 100%;">
              <div v-if="(product.promotions[0] ?? null) && product.promotions[0].compositions.length == 0" class="text-left" style="width: 100%;">
                <span style="font-size: 10px;">De: <s>{{ formatMoney(getProductPrice(product)) }}</s></span>

                <div class="d-flex justify-space-between" style="width: 100%;">
                  <span>Por: {{ formatMoney(getProductPrice(product) - getPromotionDiscount(product.promotions[0], product.price)) }}</span>
                  <span style="margin-top: 3px; font-weight: normal; font-size: 9px; color: #ccc;">
                    Ver mais
                  </span>
                </div>
              </div>
              <div v-else class="d-flex justify-space-between" style="width: 100%;">
                <span >
                  {{ formatMoney(getProductPrice(product)) }}
                </span>
                <span style="margin-top: 3px; font-weight: normal; font-size: 9px; color: #ccc;">
                  Ver mais
                </span>
              </div>
            </div>
          </div>
        </div>
    </swiper-slide>
  </swiper>
</template>

<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper-vue2";
import SwiperCore, { Pagination } from "swiper";

// Import Swiper styles
import "swiper/swiper.scss";

import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";

import "./style.css";

SwiperCore.use([Pagination]);

// install Swiper modules

export default {
  props: ["products"],
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {};
  },
  methods: {
    goToProduct(productId) {
      this.$router.push(`produto/${productId}`);
    }
  },
};
</script>

<style scoped>
.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid #2980b9;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 15px 0;
  background-color: #DD1C1E;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  color: #fff;
  font: 700 18px/1 'Lato', sans-serif;
  font-size: 10px;
  text-shadow: 0 1px 1px rgba(0,0,0,.2);
  text-transform: uppercase;
  text-align: center;
}

/* top left*/
.ribbon-top-left {
  top: -1px;
  left: -1px;
}
.ribbon-top-left::before,
.ribbon-top-left::after {
  display: none;
  border-top-color: transparent;
  border-left-color: transparent;
}

.ribbon-top-left span {
  right: -17px;
  top: 16px;
  height: 4px;
  padding-top: 7px;
  padding-right: 20px;
  transform: rotate(-45deg);
}

.slide-overlay {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: #fff;
  font-weight: bold;
  max-width: 250px;
  text-transform: uppercase;
  align-items: flex-start;
  position: absolute; 
  top: 0px;
  font-size: 12px;
  background: linear-gradient(359.85deg, #000000 -14.41%, rgba(0, 0, 0, 0));
  width: 100%;
  height: 100%;
  border-radius: 24px;
}

.promotion {
  position: relative;
  top: 4;
  left: -10px;
  top: 4px;
  color: #fff;
}
</style>