<template>
  <v-card
    :color="current && current.id == address.id ? 'orange' : ''"
    :class="current && current.id == address.id ? 'current-address' : ''"
    :outlined="true"
  >   
        <v-layout class="py-3">
            <div class="d-flex flex-column mb-5 pl-2" @click="toggleAddress">
                <span style="font-size: 16px;" class="gray--text mb-2">Endereço de entrega</span>
                <div>
                    <span>{{ address?.street }}</span>,
                    <span>{{ address?.number }}</span>
                    <!-- TODO: Adicionar referência -->
                    <!-- <span v-if="">{{ address?.number }}</span> -->
                </div>
                <div>
                    <span v-if="address?.district">{{ address?.district }}</span>
                    <span v-if="address?.city"> - {{ address?.city }}</span>
                    <span>{{ address?.state }}</span>
                </div>
                <div class="mt-1">
                    <span class="ml-3 caption gray--text">- Identificador: {{ address?.name }}</span><br/>
                    <span
                        v-if="theme?.district_tax && address?.tax > 0"
                        class="ml-3 caption gray--text"
                    >
                        - Taxa de entrega {{ formatMoney(address?.tax) }}
                    </span>
                </div>
            </div>
            <v-layout column justify-center align-end>
                <v-layout justify-center align-center>
                    <v-flex class="mr-4" row>
                        <!-- <v-btn color="primary" fab small @click.stop="goTo('/endereco/' + address.id + '/editar')">
                            <v-icon>mdi-circle-edit-outline</v-icon>
                        </v-btn> -->
                        <v-radio
                            ref="radioBtn"
                            class="mb-0"
                            :value="address.id"
                        ></v-radio>
                    </v-flex>
                    <v-flex column align-self-start>
                        <div  class="mb-1" @click="(e) => e.preventDefault()">
                            <v-menu
                                bottom
                                origin="center center"
                                transition="scale-transition"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        v-bind="attrs"
                                        v-on="on">mdi-dots-vertical</v-icon>

                                </template>

                                <v-list>
                                    <v-list-item
                                        v-for="(item, i) in items"
                                        :key="i"
                                    >
                                    <v-list-item-title @click.stop="item.action">{{ item.title }}</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                                </v-menu>
                        </div>
                    </v-flex>
                </v-layout>
            </v-layout>
        </v-layout>
    </v-card>
</template>

<script>
export default {
    name: 'addressCard',
    props: ['address', 'current', 'theme'],
    data() {
        return {
            items: [
                { title: "Editar", action: this.edit},
                { title: "Excluir", action: this.destroy}
            ]
        }
    },
    methods: {
        toggleAddress() {
            let elem = this.$refs.radioBtn.$el;
            elem.click();
        },
        edit() {
            this.$router.push('/endereco/' + this.address.id + '/editar');
        },
        destroy() {
            this.$emit('destroy', this.address.id);
        },
        setAddress(address) {
            this.$emit('settedAddress', address);
        },
    }
}
</script>

<style scoped>
    .current-address {
        color: #fff;
    }
    .current-address .v-card__subtitle{
        color: #fff;
    }
    .current-address button {
        color: #fff;
    }
</style>