<template>
  <div>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          class="d-flex align-end mt-1"
          style="border-radius: 0 0 15px 15px !important"
          v-bind="attrs"
          v-on="on"
        >
          <v-btn class="mt-3" color="primary" block>
            Adicionar novo
          </v-btn>
        </div>
      </template>
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-container>
          <create-card @cardSaved="cardSaved" :withoutRedirect="true" />
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CreateCard from "@/views/cards/Create.vue";

export default {
  name: "new-card-form-dialog",
  props: ['theme', 'user'],
  data() {
    return {
      dialog: false,
    };
  },
  components: {
    CreateCard
  },
  methods: {
    update(key, value) {
      this.$emit("update", key, value);
    },
    cardSaved(payload) {
      this.dialog = false;
      this.$emit("cardSaved", payload);
    },
  },
};
</script>