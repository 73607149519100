<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500" scrollable>
      <template v-slot:activator="{ on, attrs }">
        <div class="d-flex flex-column justify-end">
          <div
            class="d-flex justify-center mt-5 text-upper"
            v-bind="attrs"
            v-on="on"
          >
            <a class="clear-text" @click="$emit('fetch')">Ver indicações</a>
          </div>
        </div>
      </template>

      <v-card>
        <v-card-title class="text-h5 success white--text">
          Minhas indicações
        </v-card-title>

        <v-card-text class="p-0">
          <div class="mt-1">
            <v-list two-line>
              <template v-for="(indication, index) in indications">
                <v-list-item :key="indication.name">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title
                        class="text--primary"
                        v-text="indication.customer_name"
                      ></v-list-item-title>

                      <v-list-item-subtitle
                        class="text--primary"
                        v-text="`Pedido: ${indication.hash}`"
                      ></v-list-item-subtitle>

                      <v-list-item-subtitle
                        v-text="
                          `Status do pedido: ${
                            getOrderStatus(indication.status)?.text
                          }`
                        "
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-list-item>

                <v-divider
                  v-if="index < indications.length - 1"
                  :key="index"
                ></v-divider>
              </template>
            </v-list>
            <v-layout v-if="loading" class="text-center mt-5">
              <v-flex>
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </v-flex>
            </v-layout>
            <v-layout v-if="!loading && indications.length === 0" class="text-center mt-8">
              <v-flex>
                <v-icon color="primary" size="50">mdi-alert-circle-outline</v-icon>
                <p class="text--primary font-weight-bold mt-3">Voce ainda não possui indicações</p>
              </v-flex>
            </v-layout>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false"> Fechar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
    
  <script>
export default {
  name: "indications-dialog",
  props: ["indications", "loading"],
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    fetchIndications() {
      this.$emit("fetch");
    },
  },
};
</script>