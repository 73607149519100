<template>
  <v-layout column class="mt-5">
    <page-title title="meus endereços"></page-title>
    <div class="mt-10">
      <div>
        <div class="container">
          <v-layout v-if="loading" class="text-center">
            <v-flex>
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-flex>
          </v-layout>
          <div v-else>
            <v-radio-group v-model="currentAddressId" @change="changedCurrentAddress">
              <v-row>
                <v-col v-for="(address, i) in addresses" :key="i" cols="12" sm="12" md="6" xl="3">
                  <address-card :theme="theme" :address="address" @destroy="destroy" />
                </v-col>
              </v-row>
            </v-radio-group>
          </div>
        </div>
      </div>
      <div>
        <v-btn
          class="mt-3"
          color="primary"
          dark
          @click="goTo('/novo-endereco')"
          rounded=""
          block
        >
          Adicionar
        </v-btn>
      </div>
    </div>
  </v-layout>
</template>

<script>
import AddressCard from '@/components/cards/AddressCard'
import PageTitle from '@/components/layouts/PageTitle'
import { mapState, mapActions } from 'vuex';

export default {
  name: 'address-index',
  components: {
    AddressCard,
    PageTitle
  },
  computed: {
    ...mapState({
        theme: (state) => state.theme.theme,
        user: state => state.auth.user,
        addresses: state => state.addresses.addresses,
        loading: state => state.addresses.loading,
    })
  },
  data() {
    return {
      currentAddressId: "",
    }
  },
  async created() {
    if (this.addresses.length == 0) {
      await this.getAddresses({ search: null });
    }

    this.currentAddressId = this.user.current_address[0].id;

  },
  methods: {
    ...mapActions(['getAddresses', 'setCurrentAddress', 'destroyAddress']),
    changedCurrentAddress() {
      let currentAddressId = this.currentAddressId;

      let newAddress = this.addresses
        .filter((address) => address.id == currentAddressId);

      this.$store.commit('setCurrentAddress', {
        address: newAddress
      });

      this.setCurrentAddress({ addressId: currentAddressId });

      this.$toast.success("Endereço padrão alterado para: " + newAddress[0]?.name ?? null);
    },
    destroy(addressId) {
      let newAddresses = this.addresses
        .filter((address) => address.id !== addressId);

      this.$store.commit('setAddresses', {
        addresses: newAddresses
      });
      
      this.destroyAddress({ addressId });
    },
  }
}
</script>