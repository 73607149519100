<template>
  <v-row justify="center">
    <v-dialog v-model="open" max-width="350">
      <v-card>
        <v-card-title class="text-h5">
          {{ title ?? 'title' }}
        </v-card-title>

        <v-card-text>
          <slot></slot>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="$emit('close', true)">
            {{ actionText ?? 'ok' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  export default {
    name: 'generic-dialog',
    props: ['title', 'actionText', 'open'],
    data () {
      return {
      }
    },
  }
</script>