import client from '@/api/client';

export async function index({ search, page }) {
    return await client.get(`/orders?page=${page}`);
}

export async function show({ id }) {
    return await client.get(`/orders/${id}`);
}

export async function status({ id }) {
    return await client.get(`/orders/${id}/status`);
}

export async function create({ form }) {
    return await client.post(`/orders`, form);
}