import client from '@/api/client';

export async function index() {
    return await client.get(`/indications`);
}

export async function getCode() {
    return await client.get(`/indications/code`);
}

export async function totalIndications() {
    return await client.get(`/indications/total`);
}