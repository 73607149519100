<template>
  <div
    class="mx-3 notranslate"
    translate="no"
  >
    <v-list>
      <v-list-item-group
        v-for="(composition, i) in compositions"
        v-model="list[composition.name]"
        :multiple="composition.max_selection > 1"
        :max="composition.max_selection > 1 ? composition.max_selection : null"
        :key="`subheader-${i}`"
        :id="composition.name.toLowerCase()"
      >
        <template>
          <div >
            <v-subheader>
              <span 
                class="primary--text"
                style="font-size: 14px; margin-bottom: -20px;">
                  <span class="font-weight-bold text-uppercase">{{ composition.name }}</span>
                  <span class="caption gray--text" v-if="composition.min_selection && composition.min_selection < 1 || !composition.min_selection">
                    <v-chip
                      label
                      x-small
                      class="ml-1"
                      outlined
                      text-color="green"
                    >
                      opcional
                    </v-chip>
                  </span>
                  <span class="caption gray--text" v-if="composition.min_selection && composition.min_selection > 0">
                    <v-chip
                      label
                      x-small
                      class="ml-1"
                      outlined
                      text-color="green"
                    >
                      obrigatório
                    </v-chip>
                  </span>
                  <span class="caption gray--text" v-if="composition?.use_max_value_as_price && (composition.max_selection && composition.max_selection > 1)">
                    <v-chip
                      label
                      x-small
                      class="ml-1"
                      outlined
                      text-color="info"
                    >
                      maior valor será considerado final
                    </v-chip>
                  </span>
                  <span class="caption gray--text" v-if="composition.min_selection && composition.min_selection > 1"> ({{ `Min. ${composition.min_selection}`}})</span>
                  <span class="caption gray--text" v-if="composition.max_selection && composition.max_selection > 1"> ({{ `Máx. ${composition.max_selection}`}})</span>
              </span>
            </v-subheader>
          </div>
          <v-list-item
            color="primary"
            v-for="(item, j) in composition.options"
            :key="`${item.name}-${j}`"
            :value="item"
            active-class="primary--text text--accent-4"
            class="my-3"
            style="border: 1px solid #ccc; border-radius: 50px;"
          >
            <template v-slot:default="{ active, toggle }">
              <v-list-item-content @click="goToNextList(composition, compositions[i + 1] ?? null, active)">
                <v-list-item-title>
                  <div v-if="!item?.description">
                    <span>{{ item.name }}</span>
                    <span class="caption" v-if="item.additional_price > 0">
                      ({{ `${composition?.use_max_value_as_price ? '' : '+ '}${formatMoney(item.additional_price)}` }})
                    </span>
                  </div>
                  <div class="d-flex flex-column" style="font-size: 14px;" v-else>
                    <div>
                      <span>{{ item.name }}</span>
                      <span class="caption" v-if="item.additional_price > 0">
                        ({{ `${composition?.use_max_value_as_price ? '' : '+ '}${formatMoney(item.additional_price)}` }})
                      </span>
                    </div>
                    <div>
                      <span class="caption">{{ item.description }}</span>
                    </div>
                  </div>
                </v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <!-- <v-radio
                  class="mb-0"
                  :value="item"
                ></v-radio> -->
                <v-checkbox
                  :input-value="active"
                  :true-value="item"
                  :disabled="canSelect(composition, item, active)"
                  color="primary"
                  @click="toggle, goToNextList(composition, compositions[i + 1] ?? null, active)"
                ></v-checkbox>
              </v-list-item-action>
            </template>
          </v-list-item>
        </template>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
  export default {
    name: 'composition',
    props: ['compositions', 'model', 'promotions'],
    computed: {
      list: {
        get() {
          return this.model;
        },
        set(newValue, oldValue) {
          if (newValue !== oldValue) this.$emit('update:model', newValue);
        }
      }
    },
    methods: {
      canSelect(composition, item, active) {
        let currentSelectedOptions = this.model[composition.name];
        let totalSelected = 0;

        if (currentSelectedOptions instanceof Array) {
          totalSelected = currentSelectedOptions.length;
        } else {
          totalSelected = 1;
        }
        
        if (
          totalSelected == composition?.max_selection ||
          !composition?.max_selection &&
          !active
        ) {
          return true;
        }
      },
      goToNextList(composition, nextComposition, active) {
        let currentSelectedOptions = this.model[composition.name];
        let totalSelections = (currentSelectedOptions?.length ?? 0) + (active ? -1 : 1);
        
        if (
          totalSelections == composition?.max_selection ||
          !composition?.max_selection
        ) {
          if (nextComposition) {
            document.getElementById(nextComposition.name.toLowerCase()).scrollIntoView({
              behavior: 'smooth'
            });
          } else {
            document.getElementById('observation').scrollIntoView({
              behavior: 'smooth'
            });
          }
        }
      },
      isPromotional(composition, option) {
        if (!this.promotions) {
          return false;
        }

        let promotionFinded = null;

        for (let index in this.promotions) {
          let promotion = this.promotions[index];
          
          promotionFinded = promotion.compositions.find(
            (promotionComposition) => promotionComposition.name == composition && promotionComposition.option == option
          );

          if (promotionFinded) {
            return true;
          }
        }

        return false;
      }
    }
  }
</script>

<style scoped>
.v-list-item--link:before {
  background-color: currentColor;
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  border-radius: 50px;
}
</style>