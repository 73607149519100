<template>
    <div class="text-center">
        <v-bottom-sheet
            :value="value"
            @input="update"
            inset
            :persistent="loading"
        >
        <v-sheet
            class="text-center"
            style="height: 100%;"
        >
                <v-btn
                    class="mt-6"
                    text
                    color="primary"
                    @click="$emit('close', true)"
                >
                    Cancelar
                </v-btn>
                <div class="py-3 d-flex flex-column px-3" style="height: 100%;">
                    <span class="title mb-2">Esqueceu sua senha?</span>
                    <span>Preencha o seu email e enviaremos um link para você criar uma nova</span>
                    <v-text-field
                        color="primary"
                        prepend-icon="mdi-email"
                        name="forgetPasswordEmail"
                        class="mt-4"
                        v-model="forgetPasswordEmail"
                        label="Email"
                        dense
                        single-line
                        rounded
                        outlined
                    />
                    <!-- :error="!!errors?.email" -->
                        <!-- :error-messages="errors?.email && errors?.email[0]" -->
                    <v-btn class="mt-3" color="primary" @click="sendResetPassword">
                        <span>
                            Enviar
                        </span>
                        <v-icon class="ml-2">
                            mdi-send
                        </v-icon>
                    </v-btn>
                </div>
            </v-sheet>
        </v-bottom-sheet>
    </div>
</template>

<script>

export default {
    name: "ForgetPasswordSheet",
    props: ['value', 'loading'],
    data() {
        return {
            forgetPasswordEmail: ""
        }
    },
    methods: {
      update(newValue) { this.$emit('input', newValue); },
      sendResetPassword() {
        this.$toast.success("Recuperação de senha enviado");
        this.$emit('close', true)
      }
    }
}
</script>