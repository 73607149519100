<template>
    <div class="d-flex justify-space-between align-center">
        <div class="d-flex align-start" style="position: relative; width: 100%;">
            <img
                style="border-radius: 5px; height: 100px; max-width: 100px;"
                lazy-src="@/assets/product-image-placeholder.jpg"
                @error="$event.target.src=require(`@/assets/product-image-placeholder.jpg`)"
                :src="product.imagem" 
                max-width="130"
                contain>
            <v-chip
                v-if="product.promotion_id"
                class="promotion ma-2"
                color="green"
                x-small
            >
                PROMOÇÃO
            </v-chip>
            <div style="width: 100%;">
                <span class="ml-2 font-weight-medium">{{ product.name }}</span>
                <!-- TODO: Pegar valor pago no produto -->
                <div class="d-flex justify-end align-end mt-5">
                    <span class="font-weight-medium d-flex flex-column text-right">
                        <div>
                            {{ product.quantity > 1 && product.measure_unit === 'un' ? `${product.quantity}x` : '' }}
                            {{ product.quantity > 1 && product.measure_unit === 'kg' ? `${product.quantity} kg - ` : '' }}
                            {{ formatMoney(productFinalPrice) }}
                        </div>
                        <div class="font-weight-bold">
                            Total: {{ formatMoney(productFinalPrice * product.quantity) }}
                        </div>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "OrderDetailProductCard",
    props: ['product'],
    computed: {
        productFinalPrice() {
            let finalPrice = null;

            if (this.product?.final_price) {
                finalPrice = this.product.final_price / this.product.quantity;
            }

            return finalPrice ?? this.product.price
        }
    }
}
</script>

<style scoped>
.promotion {
  color: #fff;
  position: absolute !important;
  bottom: 0;
  left: calc(4% - 5px);
}
</style>