<template>
    <v-text-field
        :label="label"
        :value="value"
        @input="update"
        :hint="hint"
    ></v-text-field>
</template>

<script>
export default {
    name: "TextField",
    props: ['label', 'hint', 'search', 'value'],
    methods: {
      update(newValue) { this.$emit('input', newValue); }
    }
}
</script>