import client from '@/api/client';

export async function index({ search }) {
    return await client.get(`/addresses`);
}

export async function find({ addressId }) {
    return await client.get(`/addresses/${addressId}`);
}

export async function create({ form }) {
    return await client.post(`/addresses`, form);
}

export async function update({ addressId, form }) {
    form = {
        ...form,
        "_method": "PUT"
    };

    return await client.post(`/addresses/${addressId}`, form, { headers: { "Content-Type": "multipart/form-data" } });
}

export async function destroy({ addressId }) {
    let form = {
        address_id: addressId,
        "_method": "DELETE"
    };

    return await client.post(`/addresses/${addressId}`, form, { headers: { "Content-Type": "multipart/form-data" } });
}

export async function setCurrentAddress({ addressId }) {
    let form = {
        address_id: addressId,
        "_method": "PUT"
    };

    return await client.post(`/addresses/change-current-address`, form, { headers: { "Content-Type": "multipart/form-data" } });
}