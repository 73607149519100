<template>
  <div>
    <span class="font-weight-bold ">
      Resumo de valores <span v-if="table">- {{ table?.name }}</span>
    </span>
    <v-card class="pa-2 my-2">
      <div class="subtitle-2">
        <div class="d-flex justify-space-between gray--text">
          <span>Subtotal</span>
          <span>{{ formatMoney(total) }}</span>
        </div>
        <div class="d-flex justify-space-between gray--text" v-if="!table">
          <span>Taxa de entrega</span>
          <div>
            <v-skeleton-loader
              type="text"
              class="pt-1"
              width="50"
              v-if="themeLoading"
            ></v-skeleton-loader>
            <span
              v-else-if="
                (tax &&
                  theme?.free_rate_from >=
                    total - getCouponDiscount(coupon, total + tax)) ||
                tax
              "
              >{{ tax > 0 ? "+ " + formatMoney(tax) : "Grátis" }}</span
            >
            <span v-else>{{ "Grátis" }}</span>
          </div>
        </div>
        <div v-if="cashbackToUse" class="d-flex justify-space-between success--text">
          <span>Crédito utilizado</span>
          <span
            >-
            {{ formatMoney(cashbackToUse) }}</span
          >
        </div>
        <div v-if="coupon" class="d-flex justify-space-between success--text">
          <span>Cupom aplicado</span>
          <span
            >-
            {{ formatMoney(getCouponDiscount(coupon, total + theme.tax)) }}</span
          >
        </div>
        <div class="d-flex justify-space-between text-font-weight mt-4">
          <span>Total</span>
          <span>{{
            formatMoney(total + tax - getCouponDiscount(coupon, total + tax) - cashbackToUse)
          }}</span>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "price-summary",
  props: ['total', 'theme', 'themeLoading', 'cashbackToUse', 'table', 'coupon', 'tax'],
};
</script>