import { find, index } from "@/api/tables";

export default {
    state: {
        table: null,
        tables: [],
        loading: false,
        errors: {
            message: "",
            errors: ""
        }
    },
    mutations: {
        setTable(state, { table }) {
            state.table = table;
        },
        setTables(state, { tables }) {
            state.tables = tables;
        },
        setTableLoading(state, { loading }) {
            state.loading = loading;
        },

        setTableErrors (state, { errors }) {
            state.errors = errors;
        }
    },
    actions: {
        // commit, rootState
        async getTables({ commit }, { search = '' }) {
            try {
                commit('setTablesLoading', { loading: true });

                let { data } = await index({ search });

                commit('setTables', { tables: data });
            } catch (error) {
                let response = error.response.data;
                commit("setTablesErrors", { errors: response });
            } finally {
                commit('setTablesLoading', { loading: false });
            }
        },

        async findTable({ commit }, { tableCode }) {
            try {
                commit('setTableLoading', { loading: true });

                let { data } = await find({ tableCode });

                commit('setTable', { table: data });

                return data;
            } catch (error) {
                let response = error.response.data;
                commit("setTableErrors", { errors: response });
            } finally {
                commit('setTableLoading', { loading: false });
            }
        }
    }
}