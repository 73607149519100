<template>
  <v-card
    :color="current && current.id == card.id ? 'orange' : ''"
    :class="current && current.id == card.id ? 'current-card' : ''"
    :outlined="true"
  >   
        <v-layout class="py-3 pb-0">
            <div class="d-flex flex-column mb-5 pl-2" @click="toggleCard">
                <div class="d-flex flex-column">
                    <div>
                        #{{ card.id }}
                    </div>
                    <div class="d-flex align-center">
                        <div style="width: 60px">
                            <v-img :src="card.thumbnail" />
                        </div>
                        <div class="ml-5 d-flex flex-column">
                            <span>
                                **** {{ card.last_4 }}
                            </span>
                            <span class="caption gray--text">
                                Vencimento: {{ card.expiration_date }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <v-layout column justify-center align-end>
                <v-layout justify-center align-center>
                    <v-flex class="mr-4" row>
                        <v-radio
                            ref="radioBtn"
                            class="mb-0"
                            :value="card.id"
                        ></v-radio>
                    </v-flex>
                    <v-flex column align-self-start>
                        <div  class="mb-1" @click="(e) => e.preventDefault()">
                            <v-menu
                                bottom
                                origin="center center"
                                transition="scale-transition"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                        v-bind="attrs"
                                        v-on="on">mdi-dots-vertical</v-icon>

                                </template>

                                <v-list>
                                    <v-list-item
                                        v-for="(item, i) in items"
                                        :key="i"
                                    >
                                    <v-list-item-title @click.stop="item.action">{{ item.title }}</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                                </v-menu>
                        </div>
                    </v-flex>
                </v-layout>
            </v-layout>
        </v-layout>
    </v-card>
</template>

<script>
export default {
    name: 'credit-card',
    props: ['card', 'current', 'theme'],
    data() {
        return {
            items: [
                { title: "Excluir", action: this.destroy}
            ]
        }
    },
    methods: {
        toggleCard() {
            let elem = this.$refs.radioBtn.$el;
            elem.click();
        },
        edit() {
            this.$router.push('/card/' + this.card.id + '/editar');
        },
        destroy() {
            this.$emit('destroy', this.card.id);
        },
        setCard(card) {
            this.$emit('settedCard', card);
        },
    }
}
</script>

<style scoped>
    .current-card {
        color: #fff;
    }
    .current-card .v-card__subtitle{
        color: #fff;
    }
    .current-card button {
        color: #fff;
    }
</style>