<template>
  <v-sheet>
    <v-app-bar
      class="bg-color-style"
      flat
      fixed>
      <v-container class="nav-container-padding">
        <div :class="`d-flex container nav-width ${showBackButton ? 'flex-row-reverse' : 'flex-row '}`">
          <v-btn small color="primary" @click="drawer = !drawer" fab>
            <v-icon color="#fff"> mdi-menu </v-icon>
          </v-btn>
          <v-spacer v-if="showBackButton"></v-spacer>
          <v-btn small color="primary" fab v-if="showBackButton" @click="back">
            <v-icon color="#fff"> mdi-arrow-left-thick </v-icon>
          </v-btn>
          <v-spacer v-if="!showBackButton"></v-spacer>
          <v-chip
            v-if="!showBackButton"
            class="ma-2"
            :color="theme?.closed ? 'light' : 'success'"
            @click="openSchedule"
            small
          >
            <v-icon left>
              mdi-storefront-outline
            </v-icon>
            {{ theme?.closed ? "Fechado" : "Aberto" }}
          </v-chip>
        </div>
      </v-container>
        <!-- <v-app-bar-title>
          <v-text-field
            label="Digite o código"
            color="#fff"
            dense
            single-line
            rounded
            outlined
          ></v-text-field>

        </v-app-bar-title>
        <v-btn small color="primary" fab>
          <v-icon color="#fff">mdi-magnify</v-icon>
        </v-btn> -->

    </v-app-bar>
    <slot></slot>

      <v-fab-transition>
        <v-btn
          @click="goTo('/sacola')"
          v-show="showBag"
          color="primary"
          dark
          fixed
          bottom
          right
          fab
        >
          <v-badge
            color="green"
            :content="bag.reduce(
              (acc, product) => acc + (product.measure_unit == 'kg' ? 1 : product.quantity ?? 0),
              0
            )"
          >
            <v-icon>mdi-purse-outline</v-icon>
          </v-badge>
        </v-btn>
      </v-fab-transition>

    <v-navigation-drawer 
      v-model="drawer"
      fixed
      temporary
      app>
      <v-list-item class="drawer-cover" :style="`height: 100px; position: relative; background-size: cover !important; ${theme?.cover ? 'background: url(' + theme?.cover + ');' : ''}`">
        <div class="avatar-wrapper">
          <v-list-item-avatar class="custom-avatar mr-0">
            <v-img :src="userLogo"></v-img>
          </v-list-item-avatar>
          <v-list-item-content class="pa-0">
            <v-list-item-title class="caption">
              <span v-if="user">Olá, </span>
              <span v-if="user" class="font-weight-bold">{{ user.name }}</span>
              <span v-else>Olá, seja bem vindo</span>
            </v-list-item-title>
          </v-list-item-content>
        </div>

      </v-list-item>

      <div v-if="user" :style="`margin-top: 60px;`">
        <v-container>
          <div class="mx-2">
            <span style="font-size: 11px;">
              <div v-show="user?.email">
                Email: <span class="font-weight-bold">{{ user?.email }}</span><br />
              </div>
              <div v-show="birthDate">
                Aniversário: <span class="font-weight-bold">{{ birthDate }}</span>
              </div>
              Contato: <span class="font-weight-bold">{{ user?.phone }}</span>
              <div v-show="theme?.form?.active_cashback || theme?.indications_settings?.is_active">
                Créditos: <span class="font-weight-bold">{{ formatMoney(user?.credits ?? 0) }}</span>
              </div>
            </span>
          </div>
          <div class="custom-divider"></div>
        </v-container>
      </div>



      <v-list dense :class="`${!user ? 'list-top-spacer' : ''}`">
        <div v-if="!user">
          <v-list-item @click="user ? null : goTo('login')">
            <v-btn color="primary" block>Entrar</v-btn>
          </v-list-item>

          <div class="custom-divider"></div>
        </div>

        <v-list-item v-for="item in items" :key="item.title">
          <v-btn :color="item.color" class="my-2 text-left" block rounded small dark @click="goTo(item.to)">
            <v-list-item-icon class="mr-1">
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-btn>
        </v-list-item>
        <!-- <v-list-item>
          <v-btn :color="primary" class="my-2 text-left" block rounded small dark @click="deferredPrompt.prompt()">
            <v-list-item-icon class="mr-1">
              <v-icon></v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Baixar aplicativo</v-list-item-title>
            </v-list-item-content>
          </v-btn>
        </v-list-item> -->
      </v-list>
      <div class="text-center" style="position: absolute; width: 100%; bottom: 0px;">
        <div class="d-flex justify-content-center">
          <!-- <span style="font-size: 14px;">© </span> -->
          <v-img
            :src="require('@/assets/complete-logo.png')"
            contain
            class="mb-2"
            height="15"
          />
        </div>
        <!-- <span style="font-size: 12px;">Powered by Coderiser</span><br> -->
        <hr>
        v{{ $store.getters.appVersion }}
      </div>
    </v-navigation-drawer>
  </v-sheet>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { user as loggedUser } from '@/utils/auth';

export default {
  data() {
    return {
      drawer: false,
      deferredPrompt: null
    };
  },
  created () {
    window.addEventListener("beforeinstallprompt", e => {
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
    });
window.addEventListener("appinstalled", () => {
      this.deferredPrompt = null;
    });
  },
  computed: {
    ...mapState({
        theme: (state) => state.theme.theme,
        user: state => state.auth.user,
        bag: state => state.bag.bag
    }),
    showBag() {
      return this.bag.length > 0 && this.$route.name !== 'bag' && this.$route.name !== 'checkout' && this.$route.name !== 'product-show';
    },
    showBackButton() {
      return this.$route.name !== 'home';
    },
    birthDate() {
      if (!this?.user?.birth_date) return '';

      const [year, month, day] = this?.user?.birth_date.split('-')
      return `${day}/${month}`;
    },
    userLogo() {
      return this?.user?.avatar ?? require("@/assets/user-icon.png")
    },
    items() {
        let items = [
          { title: "Home", to: '/', icon: "mdi-view-dashboard", color: "primary"},
        ];

        if(this.user){
          let isIndicationsActive = this.theme?.indications_settings?.is_active;

          items.push(
            { title: 'Editar dados', to: '/editar-perfil', icon: 'mdi-account-edit', color: "primary"}
          );
          items.push(
            { title: 'Meus pedidos', to: '/pedidos', icon: 'mdi-list-box', color: "primary"}
          );

          if (this.theme.online_payment) {
            items.push(
              { title: 'Meus cartões', to: '/cartoes', icon: 'mdi-credit-card', color: "primary"}
            );
          }
          items.push(
            { title: "Endereços", to: '/enderecos', icon: "mdi-cellphone-marker", color: "primary"}
          );

          if (isIndicationsActive) {
            items.push(
              { title: "Indique e ganhe", to: '/indicacoes', icon: "mdi-share", color: "primary"}
            );
          }

          items.push(
            { title: 'Sair',   to: 'sair',   icon: 'mdi-exit-to-app', color: "dark"}
          );
        }

        return items;
      }
  },
  methods: {
    ...mapActions(['logout']),
    openSchedule() {
      this.$store.commit('setOpenSchedule', {
          openSchedule: true
      });
    },
    goTo(to){
      if (this.$route.path === to) return;

      if (to === 'sair') {
        this.logout();
        return;
      }
      
      this.$router.push(to);
    },
    back() {
      this.$router.back();
    }
  },
};
</script>

<style scoped>
  .drawer-cover {
    width: 100%;
    background-size: cover !important;
    background-color: var(--v-secondary);
  }

  .bg-color-style {
    background-color: rgba(0, 0, 0, 0) !important;
  }
  
  .nav-width {
    max-width: 1400px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  @media (max-width: 768px) {
    .nav-container-padding {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
  }


  @media (min-width: 768px) {
    .bg-color-style {
      background-color: var(--v-primary) !important;
    }

    .nav-width {
      max-width: 1400px !important;
    }
  }

  .list-top-spacer {
    margin-top: 70px;
  }

  .custom-divider {
    margin: 20px auto;
    background: var(--v-secondary);
    height: 2px;
    width: 60%;
  }

  .avatar-wrapper {
    position: absolute !important;
    left: 50% !important;
    bottom: -125px !important;
    text-align: center !important;
    transform: translate(-50%, -50%) !important;
  }

  .custom-avatar {
    border: 3px solid var(--v-secondary);
    width: 100px !important;
    height: 100px !important;
  }
</style>