<template>
    <v-row>
        <v-col cols="6" md="6" lg="4" xl="2" v-for="i in 8" :key="i">
            <v-card 
                outlined
                style="border-radius: 15px;"
                class="d-flex flex-column">
                <v-skeleton-loader
                    type="image"
                    width="100%"
                    height="200"
                    style="border-radius: 15px;"
                ></v-skeleton-loader>
                <div class="d-flex flex-column align-items-center">
                    <v-skeleton-loader
                        class="mt-2"
                        type="text"
                        width="80%"
                    ></v-skeleton-loader>
                </div>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: "product-grid-card-skeletons"
}
</script>