<template>
  <v-layout column class="mt-5">
    <page-title title="meus pedidos"></page-title>
    <div class="mt-10">
      <div class="container">
        <div
          v-infinite-scroll="loadMoreOrder"
          infinite-scroll-disabled="busy"
          :infinite-scroll-distance="orders.meta.current_page * 50"
        >
          <div
            v-for="(orders, date) in groupedOrder"
            :key="date"
            style="margin-top: -20px"
          >
            <div class="font-weight-bold text-uppercase py-4 mt-5 gray--text">
              {{ formatDateToHuman(orders[0].created_at) }}
            </div>
            <v-row>
              <v-col
                v-for="(order, i) in orders"
                :key="i"
                cols="12"
                sm="12"
                md="6"
                xl="3"
              >
                <order-card :order="order" />
              </v-col>
            </v-row>
          </div>
        </div>
        <v-layout v-if="loading" class="text-center mt-5">
          <v-flex>
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-flex>
        </v-layout>
      </div>
    </div>
  </v-layout>
</template>

<script>
import OrderCard from "@/components/cards/OrderCard";
import PageTitle from "@/components/layouts/PageTitle";
import { mapState, mapActions } from "vuex";

export default {
  name: "orders-index",
  components: {
    OrderCard,
    PageTitle,
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      orders: (state) => state.orders.orders,
      loading: (state) => state.orders.loading,
    }),
    groupedOrder() {
      return this.orders.data.reduce((group, order) => {
        let { order_date } = order;
        group[order_date] = group[order_date] ?? [];
        group[order_date].push(order);
        return group;
      }, {});
    },
  },
  data() {
    return {
      busy: false,
    };
  },
  async created() {
    if (this.orders.data.length == 0) {
      await this.getOrders({ search: null });
    }
  },
  methods: {
    ...mapActions(["getOrders"]),

    async loadMoreOrder() {
      let cantLoadMore = this?.orders.data.length >= this?.orders?.meta.total;

      if (this.busy || this.loading || cantLoadMore) return;

      this.busy = true;

      await this.getOrders({
        page: this.orders.meta.current_page + 1,
      });

      this.busy = false;
    },

    goTo(to) {
      if (this.$route.path === to) return;

      if (to === "sair") {
        this.logout();
        return;
      }

      this.$router.push(to);
    },
  },
};
</script>