import Vue from 'vue'
import App from './App.vue'
import '../registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios'
import formatters from '@/utils/formatters';
import infiniteScroll from './plugins/infinite-scroll'
import toast from './plugins/toast';
import vuetify from './plugins/vuetify'
import vuePaycard from './plugins/vue-paycard'
import vueSplash from './plugins/vue-splash'
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from "firebase/messaging";

// const firebaseConfig = {
//   apiKey: "AIzaSyC6nHnvDp7uDtQDmRNXusmchR8QH8-cRT4",
//   authDomain: "orderfy-96a20.firebaseapp.com",
//   projectId: "orderfy-96a20",
//   storageBucket: "orderfy-96a20.appspot.com",
//   messagingSenderId: "444104602596",
//   appId: "1:444104602596:web:f0f8c1257de5813c7f4df1",
//   measurementId: "G-62R491XTZ3"
// };

// const app = initializeApp(firebaseConfig);
// const messaging = getMessaging(app);

// messaging
//   .requestPermission()
//   .then(() => {
//     console.log('Permissão de notificação concedida.');
//     return messaging.getToken();
//   })
//   .then((token) => {
//     console.log('Token de notificação:', token);
//     // Envie o token para o servidor para salvar no usuário correspondente.
//   })
//   .catch((error) => {
//     console.error('Erro ao solicitar permissão de notificação:', error);
//   });

// messaging.onMessage((payload) => {
//   console.log('Mensagem recebida:', payload);
// });

Vue.config.productionTip = false

Vue.mixin({
  methods: {
    ...formatters
  }
})

new Vue({
  router,
  store,
  vuetify,
  vuePaycard,
  vueSplash,
  axios,
  infiniteScroll,
  toast,
  render: h => h(App),
  beforeCreate() {
    // this.$store.commit('initialiseTheme');
    this.$store.commit('initialiseUser');
		this.$store.commit('initialiseStore');
	},
}).$mount('#app')
